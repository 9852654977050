import Vue from "vue";
import Vuetify from "vuetify/lib";
import Download from "@/icons/download.vue";
import DisabledDownload from "@/icons/disabled-download.vue";
import Edit from "@/icons/edit.vue";
import Pdf from "@/icons/pdf.vue";
import Trash from "@/icons/trash.vue";
import Warning from "@/icons/warning.vue";
import PaginationLeft from "@/icons/pagination-left.vue";
import PaginationRight from "@/icons/pagination-right.vue";
import Alarm from "@/icons/alarm.vue";
import Lock from "@/icons/lock.vue";
import NotificationImportant from "@/icons/notification-important";
import Notification from "@/icons/notification";
import NotificationPrimary from "@/icons/notification-primary";
import NotificationNone from "@/icons/notification-none";
import LockPerson from "@/icons/lock-person";

import "material-design-icons-iconfont/dist/material-design-icons.css";

const opts = {
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#005D55",
        secondary: "#F2F4F8",
        accent: "#EEAF30",
        error: "#F65353",
        info: "#34B233",
        success: "#34B233",
        warning: "#F65353",
        light: "#E3F5F3",
        border: "#DCDCDC",
        background: "#CCDFDD",
        whiteBackground: "#FFFFFF",
        inactive: "#d8d8d8"
      }
    },
    options: { customProperties: true }
  },
  icons: {
    iconfont: "md", // https://vuetifyjs.com/en/customization/icons/#install-material-icons
    values: {
      disabledDownload: {
        component: DisabledDownload
      },
      download: {
        component: Download
      },
      pdf: {
        component: Pdf
      },
      trash: {
        component: Trash
      },
      edit: {
        component: Edit
      },
      paginationLeft: {
        component: PaginationLeft
      },
      paginationRight: {
        component: PaginationRight
      },
      warning: {
        component: Warning
      },
      alarm: {
        component: Alarm
      },
      lock: {
        component: Lock
      },
      notificationImportant: {
        component: NotificationImportant
      },
      notification: {
        component: Notification
      },
      notificationPrimary: {
        component: NotificationPrimary
      },
      notificationNone: {
        component: NotificationNone
      },
      lockPerson: {
        component: LockPerson
      }
    }
  }
};

Vue.use(Vuetify);

export default new Vuetify(opts);
