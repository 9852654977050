import CryptoJS from "crypto-js";
import * as db from "@/shared/database.js";

export default {
  isEncrypted(entityName) {
    return ["user", "employees"].includes(entityName);
  },
  encryptEntity(entityData) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(entityData),
      "toronto hydro secret key 991"
    ).toString();
  },

  decryptEnitity(entityData) {
    var bytesData = CryptoJS.AES.decrypt(
      entityData,
      "toronto hydro secret key 991"
    );
    return JSON.parse(bytesData.toString(CryptoJS.enc.Utf8));
  },

  openDataBase() {
    return db.openDatabase();
  },

  // Entity methods

  async deleteAllAsync(entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.clear();
    });
  },

  async deleteEntityById(id, entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.delete(id);
    });
  },

  async saveEntitiesAsync(entities, entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      entities.forEach(entity => {
        store.put(
          this.isEncrypted(entityName) ? this.encryptEntity(entity) : entity,
          entity.id
        );
      });
    });
  },

  async saveEntityByIdAsync(entity, entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.put(
        this.isEncrypted(entityName) ? this.encryptEntity(entity) : entity,
        entity.id
      );
    });
  },

  async saveEntityAsync(key, value, entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readwrite");
      trans.oncomplete = () => {
        return resolve(true);
      };

      let store = trans.objectStore(entityName);
      store.put(value, key);
    });
  },

  async getEntitiesAsync(entity) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entity], "readonly");
      trans.oncomplete = () => {
        return resolve(entityObject);
      };

      let store = trans.objectStore(entity);
      let entityObject = [];
      const isEncrypted = this.isEncrypted(entity);
      const request = store.openCursor();
      request.onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          entityObject.push(
            isEncrypted ? this.decryptEnitity(cursor.value) : cursor.value
          );
          cursor.continue();
        }
      };
    });
  },

  async getEntityAsync(entityName) {
    let database = await this.openDataBase();

    return new Promise(resolve => {
      let trans = database.transaction([entityName], "readonly");
      trans.oncomplete = () => {
        return resolve(entityObject);
      };

      let store = trans.objectStore(entityName);
      let entityObject = {};

      store.openCursor().onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          entityObject = this.isEncrypted(entityName)
            ? this.decryptEnitity(cursor.value)
            : cursor.value;
          cursor.continue();
        }
      };
    });
  },

  async getEntityByIdAsync(entityName, id) {
    let database = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = database.transaction([entityName], "readonly");
      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let entityObject = objectStore.get(id);
    });
  },

  async getEntityByIndexAsync(entityName, indexName, id) {
    let database = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = database.transaction([entityName], "readonly");

      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let objectIndex = objectStore.index(indexName);
      let entityObject = objectIndex.get(id);
    });
  },

  async getEntityIndexAsync(entityName, indexName, expression) {
    let database = await this.openDataBase();
    return new Promise(resolve => {
      let transaction = database.transaction([entityName], "readonly");

      transaction.oncomplete = () => {
        return resolve(entityObject);
      };
      let objectStore = transaction.objectStore(entityName);
      let objectIndex = objectStore.index(indexName);
      let entityObject = [];
      objectIndex.openCursor().onsuccess = e => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value[indexName] === expression)
            entityObject.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async isExistAsync(storeName) {
    let database = await this.openDataBase();
    return database.objectStoreNames.contains(storeName);
  }
};
