import * as db from "@/shared/database.js";

export const UsersMixin = {
  data() {
    return {
      users: []
    };
  },

  async created() {
    const handle = await db.mkHandle(this.$bus);
    const users = await db.fetchUsers(handle);

    this.users = users;
  }
};
