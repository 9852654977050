import emitter from "@/shared/event-emitter.js";

const bus = emitter();

const BusPlugin = {
  install(Vue) {
    Vue.prototype.$bus = bus;

    Vue.mixin({
      data() {
        return {
          mittListeners: []
        };
      },

      methods: {
        $listen(event, callback) {
          const wrapper = (...args) => {
            console.debug(`calling ${event} on ${this.$options.name}`);
            return callback(...args);
          };

          this.$bus.on(event, wrapper);
          this.mittListeners.push({ event, callback: wrapper });
        },

        async $send(event, payload = {}) {
          await this.$bus.emit(event, payload);
        },

        $clearBus() {
          for (const { event, callback } of this.mittListeners) {
            this.$bus.off(event, callback);
          }
        }
      },

      beforeDestroy() {
        this.$clearBus();
      }
    });
  }
};

export { BusPlugin, bus };
