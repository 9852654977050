function fetchGlobalNamespace() {
  if (typeof self !== "undefined") {
    return self;
  } else if (typeof window !== "undefined") {
    return window;
  } else if (typeof global !== "undefined") {
    return global;
  }
}

const globalNamespace = fetchGlobalNamespace();

export default globalNamespace;
export { fetchGlobalNamespace };
