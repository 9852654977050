<template>
  <v-input :rules="rules" :value="value">
    <v-row
      class="d-flex flex-grow-1 flex-shrink-1 flex-column align-stretch input-container"
      id="stage-container"
      ref="stageContainer"
    >
      <div
        class="d-flex flex-wrap border-line rounded-t controls"
        ref="toolsContainer"
      >
        <touch-button
          :button-class="{ 'ma-1': true, primary: isDone }"
          tooltip-text="I'm Done"
          :disabled="lockAllControls || isSaving || !hasContent"
          @click="handleDoneClick"
          :dark="isDone"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>check</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Full Screen Mode"
          :disabled="false"
          @click="handleFullScreenClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>{{ fullscreenIcon }}</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Select Traffic Layout"
          :disabled="lockAllControls || isDone || loadingBackgroundAssetSources"
          @click="handleSetBackgroundClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>insert_photo</v-icon>
        </touch-button>

        <touch-button
          :button-class="{ 'ma-1': true, primary: mode === MODES.Stamp }"
          tooltip-text="Insert Traffic Stamps"
          :disabled="lockAllControls || isDone || loadingStampAssetSources"
          @click="handleAddStampClick"
          :dark="mode === MODES.Stamp"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>dashboard_customize</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Stop Inserting Traffic Stamps"
          :disabled="lockAllControls || isDone || mode !== MODES.Stamp"
          @click="handleStopStampClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>close</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Insert Text"
          :disabled="lockAllControls || isDone"
          @click="handleAddTextClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>text_rotation_none</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Insert Arrow"
          :disabled="lockAllControls || isDone"
          @click="handleAddArrowClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>transit_enterexit</v-icon>
        </touch-button>

        <touch-button
          :button-class="{ 'ma-1': true, primary: mode === MODES.Draw }"
          :dark="mode === MODES.Draw"
          tooltip-text="Draw Freehand"
          :disabled="lockAllControls || isDone"
          @click="handlePaintClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>edit</v-icon>
        </touch-button>

        <touch-button
          :button-class="{ 'ma-1': true, primary: mode === MODES.Erase }"
          :dark="mode === MODES.Erase"
          tooltip-text="Erase"
          :disabled="lockAllControls || isDone || !hasBitmapContent()"
          @click="handleEraseClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>edit_off</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Undo"
          :disabled="lockAllControls || isDone || undoStack.length < 1"
          @click="handleUndoClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>undo</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Redo"
          :disabled="lockAllControls || isDone || redoStack.length < 1"
          @click="handleRedoClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>redo</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Download"
          :disabled="lockAllControls"
          @click="handleDownloadClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>cloud_download</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Delete Selection"
          :disabled="lockAllControls || isDone || selectedNode === null"
          @click="handleDeleteClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>remove_circle</v-icon>
        </touch-button>

        <touch-button
          button-class="ma-1"
          tooltip-text="Clear Traffic Layout"
          :disabled="lockAllControls || isDone || !canDeleteAll"
          @click="handleDeleteAllClick"
          :width="iconWidth"
          :height="iconHeight"
        >
          <v-icon>delete</v-icon>
        </touch-button>
        <div
          class="d-flex ml-2"
          v-if="mode === MODES.Erase && hasDrawLayerContent()"
        >
          <v-icon color="accent">warning</v-icon>
          <p class="warning-text">Only saved changes can be erased.</p>
        </div>
        <v-dialog persistent v-model="editText" max-width="350px">
          <v-card>
            <v-card-title>Edit the text</v-card-title>
            <v-divider></v-divider>
            <v-text-field
              class="pa-2 ma-2"
              outlined
              v-model="textValue"
              autofocus
              placeholder="Some text here"
            />
            <v-divider></v-divider>
            <v-card-actions class="dialog-actions">
              <v-btn
                @click="updateText"
                :disabled="textValue === ''"
                class="primary"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          v-model="stampsDialog"
          scrollable
          max-width="520px"
        >
          <v-card>
            <v-card-title>Select a Stamp</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-2">
              <v-row class="ma-0 d-flex justify-center">
                <v-col
                  v-for="stamp in stampAssetSources"
                  :key="`stamp_${stamp.name}`"
                  class="d-flex flex-grow-0 pa-0"
                >
                  <div
                    class="stamp-image-container d-flex align-center justify-center rounded"
                    :class="{
                      'border-line': selectedImage === `image_${stamp.name}`
                    }"
                    :style="{
                      'border-color': $vuetify.theme.themes.light.primary
                    }"
                  >
                    <div
                      class="stamp-image-box d-flex align-center justify-center"
                    >
                      <v-skeleton-loader
                        v-if="stamp.loading"
                        class="stamp-loader"
                        style="position: absolute"
                        type="image"
                      />
                      <img
                        :src="stamp.source"
                        :id="`image_${stamp.name}`"
                        style="flex-basis: 65%"
                        @click="handleClickStamp($event)"
                        @load="handleStampLoad(stamp.name)"
                        crossOrigin="Anonymous"
                      />
                      <v-icon
                        class="selected-icon"
                        v-if="selectedImage === `image_${stamp.name}`"
                        color="primary"
                        >check_circle</v-icon
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="dialog-actions">
              <v-btn @click="handleCloseStampDialog" :text="true">Cancel</v-btn>
              <v-btn @click="handleAcceptStampDialog" class="primary"
                >Select</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          persistent
          v-model="backgroundDialog"
          scrollable
          max-width="550px"
        >
          <v-card>
            <v-card-title>Select Background Image</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-2">
              <v-row class="ma-0 d-flex justify-center">
                <v-col
                  v-for="background in backgroundAssetSources"
                  :key="background.id"
                  class="d-flex flex-grow-0 pa-0"
                >
                  <div
                    class="background-image-container d-flex align-center justify-center rounded"
                    :class="{
                      'border-line': selectedBackground === background.name
                    }"
                    :style="{
                      'border-color': $vuetify.theme.themes.light.primary
                    }"
                  >
                    <div
                      class="background-image-box d-flex align-center justify-center"
                    >
                      <v-skeleton-loader
                        :loading="background.loading"
                        class="background-loader"
                        type="image"
                      />
                      <img
                        :src="background.source"
                        :id="background.name"
                        :width="background.loading ? '0' : '100%'"
                        height="auto"
                        @click="handleClickBackground($event)"
                        @load="handleBackgroundLoad(background.name)"
                        crossOrigin="Anonymous"
                      />
                      <img
                        v-if="showOverlay(background)"
                        :src="`/images/${DEFAULT_BACKGROUND_OVERLAY_NAME}`"
                        :id="DEFAULT_BACKGROUND_OVERLAY_NAME"
                        width="100%"
                        height="auto"
                        class="default-background-overlay"
                      />
                      <v-icon
                        v-if="
                          !background.loading &&
                            selectedBackground === background.name
                        "
                        class="selected-icon"
                        color="primary"
                        >check_circle</v-icon
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="dialog-actions">
              <v-btn @click="handleCloseBackgroundDialog" :text="true"
                >Cancel</v-btn
              >
              <v-btn
                @click="handleAcceptBackgroundDialog"
                class="primary"
                :disabled="!selectedBackground"
                >Select</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <dialog-message
          :showDialogMessage="clearDialog"
          displayCaption="Clear traffic layout?"
          displayText="Are you sure you want to clear the traffic layout? This action cannot be undone."
          :okAction="handleClearClick"
          :cancelAction="handleCancelClearClick"
        />
      </div>
      <v-divider></v-divider>

      <div class="border-line rounded-b stage">
        <div @drop="dropStamp($event)" @dragover="allowDrop($event)">
          <v-stage
            ref="stage"
            :config="STAGE_SIZE"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            :style="{ cursor: selectedCursor }"
          >
            <v-layer ref="backgroundLayer" name="backgroundLayer">
              <img
                :src="DEFAULT_BACKGROUND_URL"
                :id="`${DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME}`"
                crossOrigin="Anonymous"
                :style="{ cursor: selectedCursor }"
              />
            </v-layer>
            <v-layer ref="bitmapLayer" name="bitmapLayer"> </v-layer>
            <v-layer ref="drawLayer" name="drawLayer"> </v-layer>
          </v-stage>
          <v-fade-transition>
            <v-overlay
              v-if="loadingExistingBackground || loadingExistingBitmap"
              absolute
              color="#ebebeb"
            >
              <h3 class="loading-text">Loading...</h3>
            </v-overlay>
          </v-fade-transition>
          <div class="canvas-loader"></div>
        </div>
      </div>
    </v-row>
  </v-input>
</template>
<script>
import Konva from "konva";
import getEnv from "@/utilities/env.js";
import touchButton from "@/components/touch-button.vue";
import dataAccess from "@/js/data-access.js";
import dialogMessage from "@/components/submission/dialog-message.vue";
import utils from "@/shared/Utils";
import { workers } from "@/shared/workers.js";
import appsignal from "@/plugins/appsignal";
import {
  DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME,
  DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID
} from "@/shared/consts.js";

export default {
  name: "TrafficPLan",

  components: {
    touchButton,
    dialogMessage
  },

  props: {
    value: String,
    id: String,
    disabled: Boolean,
    rules: Array
  },

  data() {
    return {
      DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME,
      DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID,
      STAGE_SIZE: Object.freeze({
        width: 612,
        height: 792
      }),
      // The overall quality of the uploaded/downloaded canvas images. A higher number
      // increases the resolution of the image. For example, when the stage size is set to
      // 612x792 pixels and CONVERSION_PIXEL_RATIO is 1.5, the resulting uploaded image will
      // be 918x1188 pixel resolution, increasing the overall quality for all text and SVG
      // assets.
      CONVERSION_PIXEL_RATIO: 1,
      DEFAULT_BACKGROUND_URL: `${getEnv(
        "VUE_APP_SERVER_URL"
      )}/doc/${DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID}`,
      // Default background overlay name correspond to the image in the /public/images directory
      DEFAULT_BACKGROUND_OVERLAY_NAME: "blank-traffic-canvas-overlay.jpg",
      // Enum to track the toggled mode
      MODES: Object.freeze({
        None: 0,
        Draw: 1,
        Erase: 2,
        Stamp: 3
      }),
      mode: 0,
      selectedImage: null,
      lastSelectedImage: null,
      selectedBackground: null,
      lastSelectedBackground: null,
      imageConfig: {
        image: null,
        draggable: true
      },
      backgroundImageConfig: {
        image: null,
        y: 0
      },
      stampsDialog: false,
      backgroundDialog: false,
      clearDialog: false,
      editText: false,
      textValue: "",
      isDone: false,
      isPaint: false,
      isSaving: false,
      lastLine: null,
      selectedName: "",
      selectedNode: null,
      originalNodePosition: null,
      originalNodeRotation: null,
      originalNodeScale: null,
      scale: 1,
      scalePixelRatio: 1,
      undoStack: [],
      redoStack: [],
      loadingBackgroundAssetSources: false,
      backgroundAssetSources: [],
      loadingStampAssetSources: false,
      stampAssetSources: [],
      loadingExistingBackground: false,
      loadingExistingBitmap: false,
      transformer: new Konva.Transformer({
        anchorSize: 20,
        anchorCornerRadius: 12,
        rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
        padding: 10,
        centeredScaling: true
      }),
      canDeleteAll: false,
      hasContent: false,
      browserWidth: 0,
      browserHeight: 0
    };
  },

  async mounted() {
    // Set up painting events
    const stage = this.getStage();
    stage.on("mouseup touchend", this.handlePaintEnd);
    stage.on("mousemove touchmove", this.handlePaintDuration);

    // Scale the canvas on viewport resize
    this.scaleStage();
    window.addEventListener("resize", this.scaleStage);

    // Load the background and stamp asset source URLs
    await this.getBackgroundAssetSourcesAsync();
    await this.getStampAssetSourcesAsync();

    // Set up the transformer
    this.transformer.on("transformstart", this.handleTransformStart);
    this.transformer.on("transformend", this.handleTransformEnd);
    this.getDrawLayer().add(this.transformer);

    // Load existing canvas if it exists and lock the controls
    if (this.value) {
      this.setExistingBackground(this.value);
    }

    this.updateDimensions();
    window.addEventListener("keydown", this.handleEsc);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.scaleStage);
    window.removeEventListener("keydown", this.handleEsc);

    const styleElement = document.getElementById(
      this.getPaddingBottomStyleTag()
    );
    if (styleElement) {
      styleElement.remove();
    }
  },

  computed: {
    isFullscreen() {
      return this.$store.getters.getIsFullscreen;
    },

    fullscreenIcon() {
      return this.isFullscreen ? "fullscreen_exit" : "fullscreen";
    },

    selectedCursor() {
      let cursor = `default`;
      if (this.mode === this.MODES.Erase)
        cursor = `url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny-ps' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Ctitle%3Eeraser-svg%3C/title%3E%3Cstyle%3E tspan %7B white-space:pre %7D .shp0 %7B fill: %23000000 %7D %3C/style%3E%3Cpath id='Layer' fill-rule='evenodd' class='shp0' d='M20.44 16.24L15.5 21.19C14.71 21.97 13.45 21.97 12.66 21.19L3.47 12C1.91 10.44 1.91 7.91 3.47 6.34L7 2.81C7.79 2.03 9.05 2.03 9.84 2.81L20.44 13.41C21.22 14.2 21.22 15.46 20.44 16.24ZM8.42 4.22L4.89 7.76C4.1 8.54 4.1 9.8 4.89 10.59L8.42 14.12L13.37 9.17L8.42 4.22Z' /%3E%3C/svg%3E"),pointer`;
      else if (this.mode === this.MODES.Draw)
        cursor = `url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny-ps' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Ctitle%3Elead-pencil-svg%3C/title%3E%3Cstyle%3E tspan %7B white-space:pre %7D .shp0 %7B fill: %23000000 %7D %3C/style%3E%3Cpath id='Layer' class='shp0' d='M20.83 17.9L17.64 21.07C17.06 21.67 16.11 21.67 15.5 21.07L13.4 18.95L18.71 13.65L20.83 15.77C21.12 16.07 21.27 16.45 21.27 16.84C21.27 17.22 21.12 17.6 20.83 17.9ZM12.7 18.25L4.59 10.15L7.15 9.86L7.32 7.58L9.61 7.4L9.89 4.84L18 12.94M8.71 6.47L6.39 6.65L6.22 8.96L4.06 9.2L2.27 2.5L8.96 4.25' /%3E%3C/svg%3E"),pointer`;
      // else if (this.mode === this.MODES.Stamp)
      //   cursor = `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12,3A3,3 0 0,0 9,6C9,9 14,13 6,13A2,2 0 0,0 4,15V17H20V15A2,2 0 0,0 18,13C10,13 15,9 15,6C15,4 13.66,3 12,3M6,19V21H18V19H6Z' /%3E%3C/svg%3E"),pointer`;
      return cursor;
    },

    canMoveNodes() {
      return (
        !this.isDone &&
        this.mode !== this.MODES.Draw &&
        this.mode !== this.MODES.Erase
      );
    },
    lockAllControls() {
      return (
        this.disabled ||
        this.loadingExistingBackground ||
        this.loadingExistingBitmap ||
        this.isSaving
      );
    },
    pixelRatio() {
      return this.scalePixelRatio * this.CONVERSION_PIXEL_RATIO;
    },
    defaultBackgroundSource() {
      return this.backgroundAssetSources.length > 0
        ? this.backgroundAssetSources[0].source
        : "";
    },
    showOverlay: () => background => {
      return (
        !background.loading &&
        background.name === DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME
      );
    },
    iconWidth() {
      return 46;
    },
    iconHeight() {
      return 46;
    },
    ratio() {
      return 0.77;
    }
  },

  watch: {
    canMoveNodes: "enableNodeDrag",
    value(val) {
      if (val && !this.isDone) {
        this.scaleStage();

        if (this.backgroundAssetSources.length > 0)
          this.setExistingBackground(val);
      }
    },
    isFullscreen(val) {
      const stageContainer = this.$refs.stageContainer;
      const toolsContainer = this.$refs.toolsContainer;
      if (stageContainer && toolsContainer) {
        if (val) {
          // Get updated browser dimensions
          this.updateDimensions();
          // We calculate the width of the container from the browser height
          // minus the tools container height. This is multiplied by the
          // ratio to get the width.
          // If the toolsContainer reference isn't found, then we just
          // multiply by the ratio.
          var width = this.browserHeight * this.ratio;
          if (toolsContainer) {
            width =
              (this.browserHeight - toolsContainer.offsetHeight) * this.ratio;
          }
          // Fullscreen settings
          this.$refs.stageContainer.style.maxWidth = width + "px";
          this.updatePaddingBottomStyle(
            ".v-carousel__item { padding-bottom: 0px; }"
          );
        } else {
          // Default settings
          this.$refs.stageContainer.style.maxWidth = "856px";
          this.updatePaddingBottomStyle(
            ".v-carousel__item { padding-bottom: 200px; }"
          );
        }
        this.scaleStage();
      }
    }
  },
  methods: {
    toggleFullscreen() {
      this.$store.commit("setIsFullscreen", !this.isFullscreen);
    },
    getPaddingBottomStyleTag() {
      return "padding-bottom-dynamic-style";
    },
    updatePaddingBottomStyle(css) {
      let element = document.getElementById(this.getPaddingBottomStyleTag());

      if (!element) {
        element = document.createElement("style");
        element.id = this.getPaddingBottomStyleTag();
        document.head.appendChild(element);
      }

      element.innerHTML = css;
    },
    updateDimensions() {
      this.browserWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.browserHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    },
    // set default cursor
    setMoveCursor(node) {
      node.on("mouseenter", () => {
        this.getStage().container().style.cursor = "move";
      });
      node.on("mouseleave", () => {
        this.getStage().container().style.cursor = this.selectedCursor;
      });
    },
    // Load the background metadata and assets
    async getBackgroundAssetSourcesAsync() {
      this.loadingBackgroundAssetSources = true;

      try {
        let backgroundAssetSources = [];

        // Add default (blank) background asset
        backgroundAssetSources.push({
          name: DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME,
          id: DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID,
          source: await utils.mapSubmissionFileURLToCache(
            `${getEnv(
              "VUE_APP_SERVER_URL"
            )}/doc/${DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID}`
          ),
          loading: false
        });

        const trafficAssets = await dataAccess.getEntityIndexAsync(
          "trafficAssets",
          "type",
          "BACKGROUND"
        );

        for (let { name, file } of trafficAssets) {
          const source = await utils.mapSubmissionFileURLToCache(
            `${getEnv("VUE_APP_SERVER_URL")}/doc/${file}`
          );

          const backgroundAssetSource = {
            name,
            source,
            id: file,
            loading: true
          };

          backgroundAssetSources.push(backgroundAssetSource);
        }

        this.backgroundAssetSources = backgroundAssetSources;

        this.selectedBackground = DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME;
        this.lastSelectedBackground = DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME;
      } catch (error) {
        appsignal.sendError(error);
        this.imagesError();
        this.backgroundDialog = false;
      } finally {
        this.loadingBackgroundAssetSources = false;
      }
    },
    // Load the background metadata and assets
    async getStampAssetSourcesAsync() {
      this.loadingStampAssetSources = true;

      try {
        const _stampAssetSources = await dataAccess.getEntityIndexAsync(
          "trafficAssets",
          "type",
          "STAMP"
        );

        // Set up background image list
        _stampAssetSources.forEach(async ({ name, file }) => {
          const stampAssetSource = {
            name,
            source: await utils.mapSubmissionFileURLToCache(
              `${getEnv("VUE_APP_SERVER_URL")}/doc/${file}`
            ),
            loading: true
          };

          this.stampAssetSources.push(stampAssetSource);
        });
      } catch (error) {
        appsignal.sendError(error);
        this.imagesError();
        this.backgroundDialog = false;
      } finally {
        this.loadingStampAssetSources = false;
      }
    },

    async loadExistingLayoutAsync(imageUUIDs) {
      this.loadingExistingBackground = true;
      this.loadingExistingBitmap = true;

      // Separate the value UUIDs
      const [backgroundUUID, bitmapUUID] = imageUUIDs.split(",");

      const backgroundImageObj = this.backgroundAssetSources.find(
        ({ id }) => id === backgroundUUID
      );

      this.selectedBackground = backgroundImageObj.name;
      this.lastSelectedBackground = backgroundImageObj.name;

      const backgroundImage = new Image();
      backgroundImage.crossOrigin = "Anonymous";

      const bitmapImage = new Image();
      bitmapImage.crossOrigin = "Anonymous";

      backgroundImage.onload = () => {
        this.getBackgroundLayer().add(
          new Konva.Image({
            image: backgroundImage,
            name: "backgroundImage",
            width: this.STAGE_SIZE.width,
            height: this.STAGE_SIZE.height
          })
        );

        this.loadingExistingBackground = false;

        this.getBackgroundLayer().batchDraw();
      };

      bitmapImage.onload = () => {
        this.getBitmapLayer().add(
          new Konva.Image({
            image: bitmapImage,
            name: "bitmapImage",
            width: this.STAGE_SIZE.width,
            height: this.STAGE_SIZE.height
          })
        );

        this.loadingExistingBitmap = false;

        this.getBitmapLayer().batchDraw();
      };

      backgroundImage.src =
        backgroundImageObj && backgroundImageObj.source
          ? backgroundImageObj.source
          : await utils.mapSubmissionFileURLToCache(
              `${getEnv("VUE_APP_SERVER_URL")}/doc/${backgroundUUID}`
            );

      bitmapImage.src = await utils.mapSubmissionFileURLToCache(
        `${getEnv("VUE_APP_SERVER_URL")}/doc/${bitmapUUID}`
      );
    },
    // Load and display existing traffic plan image
    setExistingBackground(val) {
      if (!val) return;

      this.isDone = true;
      this.loadExistingLayoutAsync(val).then(() => {
        if (this.getBitmapLayer()) {
          this.hasContent = true;
        }
      });
    },
    // Toggles the draggable property on all user-draggable nodes
    enableNodeDrag(canDrag) {
      this.getDrawLayer()
        .getChildren(
          node =>
            draggableNodeClassNames.includes(node.getClassName()) &&
            node.getName() !== "backgroundImage"
        )
        .draggable(canDrag);
    },

    handleStampLoad(stampName) {
      this.stampAssetSources.find(
        asset => asset.name === stampName
      ).loading = false;
    },

    handleBackgroundLoad(backgroundName) {
      this.backgroundAssetSources.find(
        asset => asset.name === backgroundName
      ).loading = false;
    },

    // Close the stamp dialog via cancel button
    handleCloseStampDialog() {
      this.stampsDialog = false;
      this.selectedImage = this.lastSelectedImage;
    },

    // Turn on stamp mode via selecting a stamp in the dialog and
    // clicking the 'Select' button, and close the stamp dialog
    handleAcceptStampDialog() {
      this.stampsDialog = false;

      if (this.selectedImage) {
        this.mode = this.MODES.Stamp;
        this.lastSelectedImage = this.selectedImage;
      } else {
        this.mode = this.MODES.None;
        this.lastSelectedImage = null;
      }
    },

    // Opens the stamp selector dialog
    handleAddStampClick() {
      this.mode === this.MODES.None;
      this.stampsDialog = true;
    },

    // Select/deselect the clicked stamp
    handleClickStamp(event) {
      if (this.selectedImage === event.target.id) {
        this.selectedImage = null;
      } else {
        this.selectedImage = event.target.id;
      }
    },

    generateBackgroundImage(image) {
      const { width: stageWidth, height: stageHeight } = this.STAGE_SIZE;

      const ratio = Math.min(
        stageWidth / image.width,
        stageHeight / image.height
      );

      const width = image.width * ratio;
      const height = image.height * ratio;

      return new Konva.Image({
        image,
        name: "backgroundImage",
        width,
        height
      });
    },

    handleCloseBackgroundDialog() {
      this.backgroundDialog = false;
      this.selectedBackground = this.lastSelectedBackground;
    },

    // Sets the background image
    handleAcceptBackgroundDialog() {
      this.canDeleteAll = true;
      this.backgroundDialog = false;
      this.lastSelectedBackground = this.selectedBackground;
      const backgroundImage = this.generateBackgroundImage(
        document.getElementById(this.selectedBackground)
      );
      const oldBackgroundImage = this.getBackgroundLayer().getChildren()[0];

      // Set up the do/redo command
      const doCommand = () => {
        this.getBackgroundLayer().removeChildren();
        this.getBackgroundLayer().add(backgroundImage);
        this.getBackgroundLayer().batchDraw();
      };

      // Set up the undo command
      const undoCommand = () => {
        this.getBackgroundLayer().removeChildren();

        if (oldBackgroundImage) {
          this.getBackgroundLayer().add(oldBackgroundImage);
        }

        this.getBackgroundLayer().batchDraw();
      };

      // Add the undo command to the stack, returning a 'redo' command
      this.undoStack.push(() => {
        undoCommand();

        return () => {
          doCommand();
        };
      });

      // Execute the command
      doCommand();
      this.redoStack = [];
    },

    // Display the background selection dialog
    handleSetBackgroundClick() {
      this.clearSelection();

      this.backgroundDialog = true;
    },

    // Select/deselect the clicked background
    handleClickBackground(event) {
      if (this.selectedBackground !== event.target.id) {
        this.selectedBackground = event.target.id;
      }
    },

    // Turns off stamp mode
    handleStopStampClick() {
      this.selectedImage = null;
      this.lastSelectedImage = null;
      this.mode = this.MODES.None;
    },

    // Drop a text node onto the canvas
    handleAddTextClick() {
      this.clearSelection();
      this.canDeleteAll = true;

      var textNode = new Konva.Text({
        text: "Some text here",
        x: 20,
        y: 20,
        draggable: true,
        name: "text" + Math.random(),
        fontSize: 20,
        fill: "#0000FF"
      });

      textNode.on("transform", () => {
        textNode.setAttrs({
          width: Math.max(textNode.width() * textNode.scaleX(), 60),
          scaleX: 1,
          scaleY: 1
        });
      });
      this.setMoveCursor(textNode);
      textNode.on("dblclick dbltap", () => {
        if (!this.isDone) {
          this.editText = true;
          const text = textNode.text();
          this.textValue = text === "Some text here" ? "" : text;
        }
      });

      // Set up the do/redo command
      const doCommand = () => {
        this.addAndSelectNode(textNode);
      };

      // Set up the undo command
      const undoCommand = () => {
        this.removeNode(textNode);
      };

      // Add the undo command to the stack, returning a 'redo' command
      this.undoStack.push(() => {
        undoCommand();

        return () => {
          doCommand();
        };
      });

      // Execute the command
      doCommand();
      this.redoStack = [];
    },

    // Drop an arrow node onto the canvas
    handleAddArrowClick() {
      this.clearSelection();
      this.canDeleteAll = true;

      var arrow = new Konva.Arrow({
        x: 20,
        y: 40,
        points: [0, 0, 100, 100],
        pointerLength: 20,
        pointerWidth: 20,
        fill: "#0000FF",
        draggable: true,
        stroke: "#0000FF",
        name: "arrow" + Math.random(),
        strokeWidth: 4
      });
      this.setMoveCursor(arrow);
      // Set up the do/redo command
      const doCommand = () => {
        this.addAndSelectNode(arrow);
      };

      // Set up the undo command
      const undoCommand = () => {
        this.removeNode(arrow);
      };

      // Add the undo command to the stack, returning a 'redo' command
      this.undoStack.push(() => {
        undoCommand();

        return () => {
          doCommand();
        };
      });

      // Execute the command
      doCommand();
      this.redoStack = [];
    },

    // Handles dropping stamps, line drawing, and node selection
    handleStageMouseDown(event) {
      const node = event.target;
      const nodeName = node.name();
      const parent = node.getParent();

      this.setHasContent();

      // Do nothing if "Done" button is toggled on
      if (this.isDone) {
        return;
      }

      // Do nothing if the user clicked the transformer
      else if (parent && parent.className === "Transformer") {
        return;
      }

      // Do nothing if the user clicked on an eraser line
      else if (
        this.mode !== this.MODES.Erase &&
        node.getClassName() === "Line" &&
        nodeName.includes("eraser")
      ) {
        return;
      }

      // The user clicked on either empty canvas space or the background
      // image and a stamp is currently selected, so place a stamp
      else if (
        this.selectedImage !== null &&
        ["", "backgroundImage", "bitmapImage"].includes(nodeName)
      ) {
        this.canDeleteAll = true;
        this.addImage(this.selectedImage);
      }

      // The user is clicking on the background and draw mode is turned
      // on, so paint a line
      else if (this.mode === this.MODES.Draw) {
        // Even though Pencil button is enabled, we still want to edit text
        if (node && node.getClassName() === "Text") {
          this.selectNode(node);
          return;
        }

        this.canDeleteAll = true;
        this.selectNode(null);

        this.isPaint = true;

        let stage = this.getStage();
        let pos = stage.getPointerPosition();

        const line = new Konva.Line({
          stroke: "#0000FF",
          strokeWidth: 5,
          draggable: this.canMoveNodes,
          globalCompositeOperation: "source-over",
          name: "line" + Math.random(),
          points: [pos.x / this.scale, pos.y / this.scale]
        });
        this.setMoveCursor(line);
        this.lastLine = line;

        // Set up the do/redo command
        const doCommand = () => {
          this.addNode(line);
        };

        // Set up the undo command
        const undoCommand = () => {
          this.removeNode(line);
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        // Execute the command
        doCommand();
        this.redoStack = [];
      }

      // The user is clicking on the background and erase mode is turned
      // on, so paint an erasing line
      else if (this.mode === this.MODES.Erase) {
        // Even though Erase button is enabled, we still want to edit text
        if (node && node.getClassName() === "Text") {
          this.selectNode(node);
          return;
        }

        this.selectNode(null);

        this.isPaint = true;

        let stage = this.getStage();
        let pos = stage.getPointerPosition();

        const line = new Konva.Line({
          stroke: "#0000FF",
          strokeWidth: 15,
          draggable: false,
          globalCompositeOperation: "destination-out",
          name: "eraser" + Math.random(),
          points: [pos.x / this.scale, pos.y / this.scale]
        });

        this.lastLine = line;

        // Set up the do/redo command
        const doCommand = () => {
          this.addNode(line, this.getBitmapLayer(), true);
        };

        // Set up the undo command
        const undoCommand = () => {
          this.removeNode(line, this.getBitmapLayer());
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        // Execute the command
        doCommand();
        this.redoStack = [];
      }

      // If a node was clicked then select it
      else if (
        draggableNodeClassNames.includes(node.getClassName()) &&
        nodeName !== "backgroundImage" &&
        nodeName !== "bitmapImage"
      ) {
        this.selectNode(node);
      }

      // The user clicked on something that's not selectable such as the
      // canvas or background, unselect anything that's selected
      else {
        this.selectNode(null);
      }
    },

    // When a node is beginning to scale or rotate, record the
    // original values for the undo command
    handleTransformStart(event) {
      const layer = this.getDrawLayer();
      const node = layer.findOne(`.${event.target.getName()}`);

      this.originalNodeRotation = node.rotation();
      this.originalNodeScale = node.scale();
      this.originalNodePosition = node.position();
    },

    // After a node is finished scaling or rotating, set up the undo/redo commands
    handleTransformEnd(event) {
      const layer = this.getDrawLayer();
      const node = layer.findOne(`.${event.target.name()}`);

      const newNodeRotation = node.rotation();
      const newNodeScale = node.scale();
      const newNodePosition = node.position();

      const {
        originalNodeRotation,
        originalNodeScale,
        originalNodePosition
      } = this;

      if (
        originalNodeRotation !== newNodeRotation ||
        originalNodeScale.x !== newNodeScale.x ||
        originalNodeScale.y !== newNodeScale.y
      ) {
        // Set up the do/redo command
        const doCommand = () => {
          node.rotation(newNodeRotation);
          node.scale(newNodeScale);
          node.position(newNodePosition);
          this.selectNode(node);
          this.getStage().batchDraw();
        };

        // Set up the undo command
        const undoCommand = () => {
          node.rotation(originalNodeRotation);
          node.scale(originalNodeScale);
          node.position(originalNodePosition);
          this.selectNode(node);
          this.getStage().batchDraw();
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        this.redoStack = [];
      }
    },

    // When a node is beginning to move, record the original value for the undo command
    handleDragStart(event) {
      const layer = this.getDrawLayer();
      const node = layer.findOne(`.${event.target.getName()}`);
      this.originalNodePosition = node.position();
    },

    // After a node is finished moving, set up the undo/redo commands
    handleDragEnd(event) {
      const layer = this.getDrawLayer();
      const node = layer.findOne(`.${event.target.getName()}`);
      const newNodePosition = node.position();
      const { originalNodePosition } = this;

      if (
        originalNodePosition.x !== newNodePosition.x ||
        originalNodePosition.y !== newNodePosition.y
      ) {
        // Set up the do/redo command
        const doCommand = () => {
          node.position(newNodePosition);
          this.transformer.nodes([node]);
          this.getStage().batchDraw();
        };

        // Set up the undo command
        const undoCommand = () => {
          node.position(originalNodePosition);
          this.transformer.nodes([node]);
          this.getStage().batchDraw();
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        this.redoStack = [];
      }
    },

    // Delete the selected node
    handleDeleteClick() {
      if (this.selectedNode) {
        // Set up the do/redo command
        const doCommand = () => {
          this.removeNode(this.selectedNode);
          this.selectNode(null);
        };

        // Set up the undo command
        const undoCommand = () => {
          this.addAndSelectNode(this.selectedNode);
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        // Execute the command
        doCommand();
        this.redoStack = [];
      }
    },

    // Updates the text within a text node
    updateText() {
      const { textValue } = this;

      if (this.selectedNode && this.selectedNode.getClassName() === "Text") {
        const oldTextValue = this.selectedNode.text();

        // Set up the do/redo command
        const doCommand = () => {
          this.selectedNode.text(textValue);
          this.editText = false;
          this.getDrawLayer().batchDraw();
        };

        // Set up the undo command
        const undoCommand = () => {
          this.selectedNode.text(oldTextValue);
          this.editText = false;
          this.getDrawLayer().batchDraw();
        };

        // Add the undo command to the stack, returning a 'redo' command
        this.undoStack.push(() => {
          undoCommand();

          return () => {
            doCommand();
          };
        });

        // Execute the command
        doCommand();
        this.redoStack = [];
      }
    },

    // Drops a stamp onto the canvas
    dropStamp(event) {
      event.preventDefault();
      const data = event.dataTransfer.getData("stamp");
      if (data) this.addImage(data);
    },

    // Adds the passed image onto the canvas
    addImage(data) {
      const stage = this.getStage();

      let horizontal = stage.getPointerPosition().x;
      let vertical = stage.getPointerPosition().y;

      const image = document.getElementById(data);
      const adjustWidth = image.width / 2;
      const adjustHeight = image.height / 2;

      let img = new Konva.Image({
        image,
        x: horizontal / this.scale - adjustWidth,
        y: vertical / this.scale - adjustHeight,
        draggable: true,
        name: "stamp" + Math.random()
      });
      this.setMoveCursor(img);
      // Set up the do/redo command
      const doCommand = () => {
        this.addAndSelectNode(img);
      };

      // Set up the undo command
      const undoCommand = () => {
        this.removeNode(img);
      };

      // Add the undo command to the stack, returning a 'redo' command
      this.undoStack.push(() => {
        undoCommand();

        return () => {
          doCommand();
        };
      });

      // Execute the command
      doCommand();
      this.redoStack = [];
    },

    allowDrop(event) {
      event.preventDefault();
    },

    // Returns the stage instance (there should only be one)
    getStage() {
      return (this.$refs.stage && this.$refs.stage._konvaNode) || null;
    },

    // Returns the drawing layer instance (there should only be one)
    getDrawLayer() {
      return (this.$refs.drawLayer && this.$refs.drawLayer._konvaNode) || null;
    },

    // Returns the background layer instance (there should only be one)
    getBackgroundLayer() {
      return (
        (this.$refs.backgroundLayer && this.$refs.backgroundLayer._konvaNode) ||
        null
      );
    },

    // Returns the bitmap layer instance (there should only be one)
    getBitmapLayer() {
      return (
        (this.$refs.bitmapLayer && this.$refs.bitmapLayer._konvaNode) || null
      );
    },

    // Toggles paint mode
    handlePaintClick() {
      if (this.mode === this.MODES.Draw) {
        this.mode = this.MODES.None;
      } else {
        this.mode = this.MODES.Draw;
      }

      this.selectedImage = null;
      this.lastSelectedImage = null;
      this.selectNode(null);
    },

    // Toggles erase mode
    handleEraseClick() {
      if (this.mode === this.MODES.Erase) {
        this.mode = this.MODES.None;
      } else {
        this.mode = this.MODES.Erase;
      }

      this.selectedImage = null;
      this.lastSelectedImage = null;
      this.selectNode(null);
    },

    // Turns off paint mode
    handlePaintEnd() {
      this.isPaint = false;
    },

    // Tracks the painted line
    handlePaintDuration() {
      if (!this.isPaint || this.isDragging) return;

      const pos = this.getStage().getPointerPosition();

      const newPoints = this.lastLine
        .points()
        .concat([pos.x / this.scale, pos.y / this.scale]);

      this.lastLine.points(newPoints);

      this.getStage().batchDraw();
    },

    // Undo the last command
    handleUndoClick() {
      this.clearSelection();

      const undoCommand = this.undoStack.pop();
      const redoCommand = undoCommand && undoCommand();

      const newRedoCommand = () => {
        redoCommand();

        return () => {
          undoCommand();
        };
      };

      undoCommand && this.redoStack.push(newRedoCommand);
    },

    // Redo the last undone command
    handleRedoClick() {
      this.clearSelection();

      const redoCommand = this.redoStack.pop();
      const undoCommand = redoCommand && redoCommand();

      const newUndoCommand = () => {
        undoCommand();

        return () => {
          redoCommand();
        };
      };

      redoCommand && this.undoStack.push(newUndoCommand);
    },

    // Downloads an image of the canvas
    handleDownloadClick() {
      this.clearSelection();

      const link = document.createElement("a");
      link.download = "traffic_plan.jpg";
      link.href = this.getStage().toDataURL({
        type: "image/jpeg",
        pixelRatio: this.pixelRatio
      });

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Toggles locking of the canvas
    handleDoneClick() {
      this.clearSelection();
      this.undoStack = [];
      this.redoStack = [];

      if (!this.isDone) {
        // Synchronously call save function.
        this.saveChangesAsync();
      }

      this.isDone = !this.isDone;
    },

    handleFullScreenClick() {
      this.toggleFullscreen();
    },

    // Deletes all nodes and revert to default background, clears undo/redo, clears cached nodes
    handleDeleteAllClick() {
      this.clearDialog = true;
    },

    handleClearClick() {
      this.selectNode(null);
      this.clearSelection();
      this.getDrawLayer()
        .getChildren(child => child.getClassName() !== "Transformer")
        .each(child => child.destroy());
      this.getBitmapLayer().destroyChildren();
      this.getBackgroundLayer().destroyChildren();
      this.selectedBackground = null;
      this.undoStack = [];
      this.redoStack = [];
      this.canDeleteAll = false;
      this.clearDialog = false;
      this.hasContent = false;
      this.getStage().batchDraw();
    },

    handleCancelClearClick() {
      this.clearDialog = false;
    },

    addAndSelectNode(node) {
      this.addNode(node);
      this.selectNode(node);
    },

    addNode(node, layer, isStatic) {
      // Add handlers for node movement
      if (!isStatic) {
        node.on("dragstart", this.handleDragStart);
        node.on("dragend", this.handleDragEnd);
      }

      if (layer) {
        layer.add(node);
        layer.batchDraw();
      } else {
        this.getDrawLayer().add(node);
        this.getDrawLayer().batchDraw();
      }
    },

    removeNode(node, layer) {
      node.remove();

      if (layer) {
        layer.batchDraw();
      } else {
        this.getDrawLayer().batchDraw();
      }
    },

    selectNode(node) {
      if (node) {
        this.transformer.moveToTop();

        // Text transformer should scale from the top-left, images
        // should scale from the center
        if (node.getClassName() === "Text") {
          this.transformer.centeredScaling(false);
          this.transformer.enabledAnchors(["middle-left", "middle-right"]);
        } else {
          this.transformer.enabledAnchors([
            "top-left",
            "top-center",
            "top-right",
            "middle-right",
            "middle-left",
            "bottom-left",
            "bottom-center",
            "bottom-right"
          ]);
          this.transformer.centeredScaling(true);
        }

        this.selectedNode = node;
        this.transformer.nodes([node]);
      } else {
        this.selectedNode = null;
        this.transformer.nodes([]);
      }

      this.getDrawLayer().batchDraw();
    },

    clearSelection() {
      this.mode === this.MODES.None;
      this.selectedImage = null;
      this.lastSelectedImage = null;
      this.selectNode(null);
      this.getStage().batchDraw();
    },

    scaleStage() {
      const stage = this.getStage();
      const stageContainer = this.$refs.stageContainer;
      const containerWidth = stageContainer.offsetWidth;

      // Typically means that our section is not the current
      if (containerWidth === 0) return;

      let scale = containerWidth / this.STAGE_SIZE.width;

      // Just in case we want to wait until content is visible
      if (scale === 0) {
        window.setTimeout(this.scaleStage, 250);
      }

      this.scale = scale;
      this.scalePixelRatio = 1 / scale;
      stage.width(this.STAGE_SIZE.width * scale);
      stage.height(this.STAGE_SIZE.height * scale);
      stage.scale({ x: scale, y: scale });
      stage.batchDraw();
    },

    async saveChangesAsync() {
      this.isSaving = true;
      const bgUUID = this.selectedBackgroundUUID();

      try {
        // Move all non-transformer nodes from the draw layer onto the bitmap layer
        const childrenToMove = this.getDrawLayer()
          .getChildren()
          .filter(child => child.getClassName() !== "Transformer");

        childrenToMove.forEach(child => child.moveTo(this.getBitmapLayer()));

        // Convert the whole bitmap layer into an image
        const bitmapImage = await new Promise(resolve =>
          this.getBitmapLayer().toImage({
            pixelRatio: 1,
            type: "image/svg",
            callback: resolve
          })
        );

        // Remove the original nodes from the background layer
        this.getBitmapLayer().destroyChildren();

        // Insert the generated image into the background layer
        this.getBitmapLayer().add(
          new Konva.Image({
            image: bitmapImage,
            name: "bitmapImage",
            width: this.STAGE_SIZE.width,
            height: this.STAGE_SIZE.height
          })
        );

        this.getBitmapLayer().draw();
        this.getStage().batchDraw();

        const imageBlobData = await this.imageToBlobAsync(bitmapImage);
        const imageData = {
          blob: imageBlobData,
          // name: "bitmapImage.png",
          type: "image/png"
        };
        const bitmapUUID = utils.getUUID();
        this.uploadImage(imageData, bitmapUUID);
        // Submit the comma-separated UUIDs as the input value
        this.$emit("input", `${bgUUID},${bitmapUUID}`);
      } catch (err) {
        console.error(err);
      } finally {
        this.isSaving = false;
        this.mode = this.MODES.None;
      }
    },

    selectedBackgroundUUID() {
      return this.backgroundAssetSources.find(
        ({ name }) => name === this.selectedBackground
      ).id;
    },

    uploadImage(image, uuid) {
      let worker = workers.uploadWorker();
      worker.postMessage({
        action: "start",
        files: [{ file: image, uuid: uuid }],
        uri: `${getEnv("VUE_APP_SERVER_URL")}`
      });
      worker.onmessage = e => {
        switch (e.data.status) {
          case 0:
          case 1:
            this.onUploadMessage(e);
            break;
          case 3:
            appsignal.sendError(e.data.exception);
            break;
        }
      };
    },

    onUploadMessage(event) {
      if (event.data.status !== -1) {
        const bgUUID = this.selectedBackgroundUUID();
        const bitmapUUID = event.data.newFile.uuid;
        this.$emit("input", `${bgUUID},${bitmapUUID}`);
      }
    },

    // Converts an image to a blob using an in-memory canvas element
    async imageToBlobAsync(image) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      return await new Promise(resolve =>
        canvas.toBlob(blob => resolve(blob), "image/png")
      );
    },

    hasDrawLayerContent() {
      return this.getDrawLayer().getChildren().length > 1;
    },

    hasBitmapContent() {
      return this.getBitmapLayer()
        ? this.getBitmapLayer().hasChildren()
        : false;
    },

    setHasContent() {
      // const hasBitmapContent = this.getBitmapLayer().hasChildren();
      // We don't want to include the Transformer
      // const hasDrawLayerContent = this.getDrawLayer().getChildren().length > 1;
      this.hasContent = this.hasBitmapContent() || this.hasDrawLayerContent();
    },
    imagesError() {
      this.$emit(
        "alertError",
        "Traffic Images Error: Unable to get the images"
      );
    },
    handleEsc(event) {
      // ESC keycode is 27
      if (event.keyCode === 27) {
        this.$store.commit("setIsFullscreen", false);
      }
    }
  }
};

const draggableNodeClassNames = ["Arrow", "Line", "Image", "Text"];
</script>
<style scoped>
.border-line {
  border: 1px solid #e6e7eb;
}

.default-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.background-image-container {
  width: 158px;
  height: 204px;
}

.background-image-box {
  width: 154px;
  height: 200px;
}

.background-loader {
  position: relative;
  width: 154px;
  height: 200px;
}

.stamp-image-container {
  width: 100px;
  height: 100px;
}

.stamp-image-box,
.stamp-loader {
  width: 96px;
  height: 96px;
}

.background-image-box,
.stamp-image-box {
  position: relative;
  cursor: pointer;
}

.controls {
  border-bottom: none;
}

.stage {
  border-top: none;
  position: relative;
}

.input-container {
  max-width: 856px;
  min-width: 0;
}

.background-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.img-stamp {
  width: 100px;
  height: 100px;
}

.selected-stamp {
  background-color: black;
}

.dialog-actions {
  justify-content: flex-end;
}

.selected-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.loading-text {
  color: #000000;
}

.warning-text {
  font-size: 12px;
  margin: 5px;
  color: gray;
}
</style>
