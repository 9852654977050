<template>
  <div
    class="claim-slidebar"
    :style="{ width: expanded ? '300px' : '70px' }"
    @transitionend="handleTransitionEnd"
  >
    <v-icon v-if="!expanded" color="primary" @click.stop="toggle"
      >arrow_left</v-icon
    >
    <v-icon class="material-icons">{{ icon }}</v-icon>
    <span v-if="showClaimEmail" class="caption claim-email">
      {{ email }}
    </span>
    <v-icon v-if="expanded" @click.stop="toggle" color="primary"
      >arrow_right</v-icon
    >
  </div>
</template>

<script>
import { isClaimedByMe } from "@/utilities/submission.js";
import appsignal from "@/plugins/appsignal";

export default {
  name: "ClaimSlidebar",
  props: {
    captionText: {
      type: String,
      default: "test@email.com"
    },
    submission: Object,
    user: Object,
    users: Array
  },
  data() {
    return {
      expanded: false,
      showClaimEmail: false
    };
  },
  computed: {
    icon() {
      if (isClaimedByMe(this.submission, this.user)) {
        return "lock";
      }

      return "$vuetify.icons.lockPerson";
    },
    email() {
      if (isClaimedByMe(this.submission, this.user)) {
        return this.user.email;
      }

      const findUser = this.users.find(
        user => user.id === this.submission.claims[0].userId
      );

      if (findUser) {
        return findUser.email;
      }

      const span = appsignal.createSpan(span => {
        span.setNamespace("claim-slidebar.vue");
        span.setAction("email");
        span.setTags({
          submissionId: this.submission.id
        });
        span.setError(
          Error("Claim-slidebar: Could not retrieve email for submission")
        );
      });
      appsignal.send(span);

      return "Error retrieving email";
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (!this.expanded) {
        this.showClaimEmail = false;
      }
    },
    handleTransitionEnd() {
      // Clear previous timer
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // Set timeout on visibility of the email
      // otherwise it pops up during the slidebar opening transition
      if (this.expanded) {
        this.timeoutId = setTimeout(() => {
          this.showClaimEmail = true;
        }, 5);
      } else {
        this.showClaimEmail = false;
      }
    }
  }
};
</script>

<style scoped>
.claim-slidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
  height: auto;
  width: 70px;
  z-index: 1 !important;
  position: absolute;
  top: 0;
  right: -6px;
  background: white;
  box-shadow: 0 5px 5px 0 var(--v-border-base);
  border: 1px solid var(--v-border-base);
  padding: 8px;
  transition: width 0.3s ease;
}

.claim-email {
  color: var(--v-primary-base);
}
</style>
