import { v4 as uuidv4 } from "uuid";
import appsignal from "@/plugins/appsignal";

export class Utils {
  async getCachedData(cacheName, url) {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
      return false;
    }

    return await cachedResponse;
  }

  async deleteCachedData(cacheName, url) {
    const cacheStorage = await caches.open(cacheName);
    return await cacheStorage.delete(url);
  }

  async mapUrlToCache(fileUrl, fileType) {
    const cached = await this.getCachedData(
      "tailboard-submissions-files",
      fileUrl
    );
    if (cached) {
      const bodyBlob = await cached.blob();
      let blobResult = new Blob([bodyBlob], { type: fileType });

      let url = URL.createObjectURL(blobResult);

      if (fileType !== "image/png") {
        return url;
      }

      /* Validating the image for a broken image. For whatever reason,
         getCachedData retrieves blob from cache storage with a broken
         image. I verified by searching for the specific cache in local
         storage and was not able to find it. This sometimes returns a 
         broken image even if the local cache storage has been cleared!

         The following test verifies if the image is valid or not and sets
         url to null if invalid.
      */
      const isValid = await new Promise(resolve => {
        this.validateImageUrl(url, resolve);
      });

      if (!isValid) {
        URL.revokeObjectURL(url);
        return null;
      }

      return url;
    }
    return null;
  }

  validateImageUrl(url, callback) {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);

    img.src = url;
  }

  sorted(list) {
    if (!list) return [];
    return list.concat().sort((a, b) => a.position - b.position);
  }

  getDateNow() {
    const date = new Date();
    return date.toISOString().split("T")[0];
  }

  getUUID() {
    return uuidv4();
  }

  parseTextToHTML(txt) {
    if (txt) {
      let text = txt.replace(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])(?!(?:(?!<\/?a\b[^>]*>).)*?<\/a>)/gi,
        "<a href='$1'>$1</a>"
      );
      return text.replace(
        /(?!(?:(?!<\/?a\b[^>]*>).)*?<\/a>)(^|[^])(www\.[\S]+(\b|$))/gim,
        '<a target="_blank" href="http://$2">$2</a>'
      );
    } else return "";
  }

  toPascalCase(str) {
    return str
      .replace("_", " ")
      .replace(
        /\w\S*/g,
        m => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()
      );
  }

  createObservedWorker(Worker) {
    const worker = new Worker();

    worker.addEventListener("message", event => {
      if (typeof event.data === "object" && event.data != null) {
        const {
          data: { type }
        } = event;

        if (type === "breadcrumb") {
          const metadata = createAppsignalMetadata({ event });
          const breadcrumb = {
            category: event.data.category || "Web Worker",
            action: event.data.action || "N/A",
            metadata
          };

          appsignal.addBreadcrumb(breadcrumb);
        } else if (type === "error") {
          const metadata = createAppsignalMetadata({ event });
          reportError(event.data.error, metadata);
        } else {
          // noop, we only care about events that relate to error reporting
        }
      } else {
        const metadata = createAppsignalMetadata({ event });
        const error = new Error("worker event sent with empty data");
        reportError(error, metadata);
      }
    });

    worker.addEventListener("error", () => {
      const error = new Error("Generic Worker Error");
      reportError(error);
    });

    worker.addEventListener("messageerror", () => {
      const error = new Error("Could not serialize message payload");
      reportError(error);
    });

    return worker;
  }

  createWorker(Worker) {
    return new Worker();
  }

  humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    var units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  }

  /**
   * Check user agent for Safari on iOS
   * @returns {boolean}
   */
  isIosSafari() {
    return (
      navigator.userAgent.match(/iP(od|hone|ad)/) &&
      navigator.userAgent.match(/AppleWebKit/) &&
      !navigator.userAgent.match(/(Cr|Fx|OP)iOS/)
    );
  }

  /**
   * Gets Cache Response body where a Request matches in multiple caches
   * @param {string} responseTargetCache
   * @returns {Array}
   */
  //eslint-disable-next-line no-unused-vars
  async intersectCache(
    requestCache = "tailboard-files-queue",
    responseCache = "tailboard-submissions-files"
  ) {
    const self = this;
    const hasRequestCache = await caches.has(requestCache);
    let returnValue = [];
    if (!hasRequestCache) return returnValue;
    return await caches.open(requestCache).then(async function(cache) {
      return cache.keys().then(async function(keys) {
        for (let i = 0; i < keys.length; i++) {
          const uuidPos = keys[i].url.lastIndexOf("/");
          const uuid = keys[i].url.substring(uuidPos + 1);
          const response = await self.getCachedData(responseCache, keys[i].url);
          if (response)
            returnValue.push({
              uuid: uuid,
              file: await response.blob()
            });
        }
        return returnValue;
      });
    });
  }
  /**
   * Delete specified CacheStorage
   * @param {string} cacheName
   * @returns {Boolen}
   */
  async deleteCache(cacheName) {
    let isDeleted = false;
    await caches.delete(cacheName).then(boolean => {
      isDeleted = boolean;
    });
    return Promise.resolve(isDeleted);
  }

  /**
   * Delete an entry from specified CacheStorage
   * @param {string} cacheName
   * @param {string} key
   */
  async deleteCacheEntry(cacheName, key) {
    // Delete cache entry from upload queue
    let isDeleted = false;
    await caches.open(cacheName).then(cache => {
      return cache.delete(key).then(boolean => {
        isDeleted = boolean;
      });
    });
    return Promise.resolve(isDeleted);
  }

  async mapSubmissionFileURLToCache(path) {
    const cacheAvailable = await this.getCachedData(
      "tailboard-submissions-files",
      path
    );

    return cacheAvailable
      ? URL.createObjectURL(await cacheAvailable.blob())
      : path;
  }
}

function reportError(error, metadata = null) {
  appsignal.sendError(error, span => {
    if (typeof metadata === "object" && metadata != null) {
      span.setTags(metadata);
    }
  });
}

function createAppsignalMetadata({ event, path }) {
  const hasNestedMetadata = !!(event && event.data && event.data.metadata);

  return {
    ...(hasNestedMetadata ? event.data.metadata : {}),
    workerPath: path,
    origin: event.origin,
    lastEventId: event.lastEventId,
    source: event.source
  };
}

export default new Utils();
