import utils from "@/shared/Utils";

import CacheWorker from "@/shared/cache.worker.js";
import EncoderWorker from "@/shared/encoder-wav.worker.js";
import UploadWorker from "@/shared/upload.worker.js";
import UpdateWorker from "@/shared/update.worker.js";

const workers = {
  cacheWorker: () => utils.createObservedWorker(CacheWorker),
  encoderWorker: () => utils.createWorker(EncoderWorker),
  uploadWorker: () => utils.createWorker(UploadWorker),
  updateWorker: () => utils.createObservedWorker(UpdateWorker)
};

export { workers };
