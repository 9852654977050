<template>
  <div class="pl-2 pr-2">
    <v-row fill-width class="ma-0 mb-4">
      <h2>Dashboard</h2>
      <v-spacer />
      <v-btn @click="submitNew" class="ml-8 primary">New Tailboard</v-btn>
    </v-row>
    <div class="white">
      <v-tabs
        class="pl-10 pr-10"
        v-model="displayStyle"
        left
        dark
        slider-color="primary"
        background-color="white"
      >
        <v-tab class="primary--text">ACTIVE</v-tab>
        <v-tab class="primary--text">INACTIVE</v-tab>
        <v-spacer />
        <v-btn
          v-if="hasActiveFilters"
          text
          class="align-self-center mt-2 px-1 mr-4 grey lighten-3 primary--text"
          height="1.5rem"
          @click="clearActiveFilters"
          >Clear Filter</v-btn
        >
        <v-btn
          small
          class="mt-3"
          :class="[formCase == 0 ? 'primary' : 'white primary--text']"
          @click="formCase = 0"
          ><v-icon>view_module</v-icon>
        </v-btn>
        <v-btn
          small
          class="mt-3"
          :class="[formCase == 1 ? 'primary' : 'white primary--text']"
          @click="formCase = 1"
          ><v-icon>list</v-icon>
        </v-btn>
        <v-btn
          class="primary--text mb-1 mt-2 ml-2"
          text
          @click="showfilterDialog(true)"
        >
          <v-icon class="mr-3">filter_list</v-icon>
          Filter
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="displayStyle" touchless>
        <v-tab-item class="pl-10 pr-10">
          <dashboard-tab
            :tailboardFormSubmissions="tailboardFormSubmissions"
            :formCase="formCase"
            :submitIncidentReport="submitIncidentReport"
            @show="showComment($event)"
          />
        </v-tab-item>
        <v-tab-item class="pl-10 pr-10">
          <dashboard-tab
            :tailboardFormSubmissions="archivedTailboardFormSubmissions"
            :formCase="formCase"
            :submitIncidentReport="submitIncidentReport"
            @show="showComment($event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <filters-dialog
      v-for="(tab, idx) in tabs"
      :key="idx"
      :filterDialogShow="toggleFilterDialog(idx)"
      :filterName="tab.text.charAt(0).toUpperCase() + tab.text.slice(1)"
      :fieldName="tab.fieldName"
      :cancelAction="showfilterDialog"
      :doneAction="saveFilter"
      :ref="`dialogFilter_${tab.fieldName}`"
    />
  </div>
</template>

<script>
import dashboardTab from "./dashboard-tab.vue";
import dayjs from "@/plugins/dayjs";
import FiltersDialog from "@/components/filters-dialog.vue";
import tailboardController from "@/js/tailboards-controller.js";
import getEnv from "@/utilities/env.js";
import { getReviewMode } from "@/utilities/submission.js";
import { LOCAL_STORAGE_FILTER_STATE_KEY } from "@/shared/consts.js";
import { NotifiedIdMixin } from "@/mixins/notifiedId.js";

export default {
  name: "Dashboard",
  mixins: [NotifiedIdMixin],
  components: { dashboardTab, FiltersDialog },
  props: {
    formId: String,
    submissionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        {
          text: "active",
          fieldName: "tailboardFormSubmissions"
        },
        {
          text: "inactive",
          fieldName: "archivedTailboardFormSubmissions"
        }
      ],
      sw: null,
      tailboardFormSubmissionsFilterDialog: false,
      archivedTailboardFormSubmissionsFilterDialog: false,
      tailboardFormSubmissions: [],
      archivedTailboardFormSubmissions: [],
      allSubmissions: {
        tailboardFormSubmissions: [],
        archivedTailboardFormSubmissions: []
      },
      filterOptions: {
        tailboardFormSubmissions: {
          searchTerm: null,
          filters: null
        },
        archivedTailboardFormSubmissions: {
          searchTerm: null,
          filters: null
        }
      },
      formCase: localStorage.getItem("form-case")
        ? parseInt(localStorage.getItem("form-case"))
        : 0,
      displayStyle: localStorage.getItem("tabs")
        ? parseInt(localStorage.getItem("tabs"))
        : 0
    };
  },
  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  },
  created() {
    const token = this.$route.query.token;

    if (typeof localStorage !== "undefined" && token) {
      localStorage.setItem("token", this.$route.query.token);
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    } else {
      if (!localStorage.getItem("token"))
        window.location = `${getEnv(
          "VUE_APP_SERVER_URL"
        )}/login?redirect=${getEnv("VUE_APP_SERVER_CALLBACK")}`;
    }

    const localFilters = localStorage.getItem(LOCAL_STORAGE_FILTER_STATE_KEY);
    if (localFilters) {
      const parsedFilters = JSON.parse(localFilters);

      for (let { fieldName } of this.tabs) {
        const options = parsedFilters[fieldName];

        if (options) {
          const { filterList, searchTerm } = options;
          this.saveFilter(filterList, searchTerm, fieldName);
        }
      }
    }
  },
  mounted() {
    if (this.formId && this.submissionId) {
      this.$router.push({
        path: `/forms/${this.formId}/submit/${this.submissionId}`
      });
    } else {
      if (!this.isBusy) this.refreshDashboard().then(this.getOFSCSubmission);
      this.$listen("dashboard#refresh", () => {
        if (!this.isBusy) {
          this.refreshDashboard();
        } else {
          const unwatch = this.$watch("isBusy", () => {
            if (!this.isBusy) {
              this.refreshDashboard();
              unwatch();
            }
          });
        }
      });
    }
    document.addEventListener("swReady", this.swAvailable, {
      once: true
    });
  },
  watch: {
    isBusy(value) {
      if (!value) {
        this.refreshDashboard().then(this.getOFSCSubmission);
      }
    },
    displayStyle(value) {
      localStorage.setItem("tabs", value);
    },
    formCase(value) {
      localStorage.setItem("form-case", value);
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    disableCreateNew() {
      return this.user && this.user.role === "USER"
        ? this.allSubmissions.tailboardFormSubmissions.some(t => {
            return !(t.state === "SUBMITTED" && t.mode === "CLOSED");
          })
        : false;
    },
    isBusy() {
      return this.$store.getters.getIsBusy;
    },
    notifiedId() {
      return this.$store.getters.getNotifiedId;
    },
    activeTab() {
      return this.tabs[this.displayStyle];
    },
    hasActiveFilters() {
      const { fieldName } = this.activeTab;
      const filters = this.filterOptions[fieldName].filters || [];

      let result = false;

      result = filters.length > 0 || this.filterOptions[fieldName].searchTerm;

      return result;
    },
    toggleFilterDialog: vm => index => {
      return vm[`${vm.tabs[index].fieldName}FilterDialog`] || false;
    }
  },
  methods: {
    onSubmissionUpdated(updatedSubmission) {
      const index = this.tailboardFormSubmissions.findIndex(
        t => t.id === updatedSubmission.id
      );
      if (index !== -1) {
        this.tailboardFormSubmissions[index] = updatedSubmission;
        this.tailboardFormSubmissions = [...this.tailboardFormSubmissions];
      } else {
        // new tailboard form submission
        if (updatedSubmission.tailboardForm.type === "TAILBOARD") {
          this.tailboardFormSubmissions.unshift(updatedSubmission);
        }
      }
    },

    onSubmissionDeleted(submissionId) {
      const index = this.tailboardFormSubmissions.findIndex(
        t => t.id === submissionId
      );

      // check if it available in dashboard
      if (index !== -1) {
        // remove it from dashboard 'moved to archive'
        this.tailboardFormSubmissions.splice(index, 1);
      }
    },

    getOFSCSubmission() {
      // checking if ofsc tailboard:// exist
      let ofscObject = localStorage.getItem("ofsc");
      let queryObject = {};
      if (ofscObject) {
        var search = ofscObject.substring(1);
        queryObject = JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function(key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        localStorage.removeItem("ofsc");
      }

      const ofsc = queryObject.esrr ? queryObject : this.$route.query;

      const { esrr } = ofsc;

      if (!esrr) return;
      const formId = getEnv("VUE_APP_TAILBOARDFORMID");

      // 1. check database for a submission with the esrr
      let allSubmissions = Object.values(this.allSubmissions).flat();

      const submission = allSubmissions.find(
        item => item.tailboardFormHeader.esrr === esrr
      );

      // if can't find submission redirect user to submit route
      // with all the query strings and handle the creation
      // of the form with pre filled data
      if (!submission)
        this.$router.push({
          path: `/forms/${formId}/submit`,
          query: ofsc
        });
      // if there is a submission with esrr redirect to submission route
      else {
        const reviewMode = getReviewMode(submission, this.user);

        if (reviewMode)
          this.$router.push(`/preview/${submission.id}/${submission.state}`);
        else
          this.$router.push(
            `/forms/${submission.formId}/submit/${submission.id}`
          );
      }
    },
    async submitIncidentReport(incidentObject) {
      const errors = await tailboardController.addIncidentReport(
        incidentObject
      );
      return errors.length === 0;
    },
    clearActiveFilters() {
      let { fieldName } = this.activeTab;
      const option = this.filterOptions[fieldName];

      option.searchTerm = null;
      option.filters = [];
      this.$refs[`dialogFilter_${fieldName}`][0].clearAll();

      this[fieldName] = this.allSubmissions[fieldName];

      this.updateStorageFilters({ fieldName, filters: null });
    },
    async refreshDashboard() {
      const tailboardFormSubmissions = await tailboardController.getTailboardFormSubmissionsAsync();
      const archivedTailboardFormSubmissions = await tailboardController.getTailboardFormSubmissionsAsync(
        true
      );

      this.allSubmissions = {
        tailboardFormSubmissions,
        archivedTailboardFormSubmissions
      };

      this.applyFilter();

      return this.allSubmissions;

      // if (this.tailboardFormSubmissions.length > 0)
      //   this.$emit("register", this.tailboardFormSubmissions);
    },
    swAvailable(event) {
      this.sw = event.detail;
      //this.sw.postMessage("service worker message");
    },
    updateStorageFilters({ fieldName, filters }) {
      const savedFilters = localStorage.getItem(LOCAL_STORAGE_FILTER_STATE_KEY);

      const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
      parsedFilters[fieldName] = filters;

      localStorage.setItem(
        LOCAL_STORAGE_FILTER_STATE_KEY,
        JSON.stringify(parsedFilters)
      );
    },
    saveFilter(filterList, searchTerm, fieldName) {
      if (searchTerm && searchTerm.length === 0 && filterList.length === 0) {
        this.tailboardFormSubmissionsFilterDialog = false;
        this.clearActiveFilters();
        return;
      }

      if (!fieldName) fieldName = this.activeTab.fieldName;

      this.updateStorageFilters({
        fieldName,
        filters: { filterList, searchTerm }
      });

      const mappedFilters = filterList
        .map(t => {
          return {
            // boolean: t.selectedBoolean,
            field: t.selectedFieldType ? t.selectedFieldType.value : null,
            operator: t.selectedOperator ? t.selectedOperator.value : null,
            value:
              t.selectedCriteria && t.selectedCriteria.value
                ? t.selectedCriteria.value
                : t.selectedCriteria
          };
        })
        .filter(({ field, operator, value }) => field && operator && value); // NOTE: if the boolean field ever comes back, add it here

      const option = this.filterOptions[fieldName];
      option.searchTerm = searchTerm ? searchTerm : null;
      option.filters = mappedFilters;

      if (fieldName === "tailboardFormSubmissions")
        this.tailboardFormSubmissionsFilterDialog = false;
      else this.archivedTailboardFormSubmissionsFilterDialog = false;

      this.applyFilter(fieldName);
    },
    applyFilter(tab) {
      const filterField = fieldName => {
        let { filters, searchTerm } = this.filterOptions[fieldName];
        const all = this.allSubmissions[fieldName];

        if (!searchTerm && (!filters || filters.length === 0)) {
          this[fieldName] = all;
          return;
        }

        let filteredResults = all;

        // First we search the following properties
        // with the provided searchTerm
        // NOTE: the backend uses the same fields for this search
        if (searchTerm) {
          searchTerm = searchTerm.toLowerCase();

          const searchParamsFields = [
            "tailboardFormHeader.projectName",
            "tailboardFormHeader.workOrderNumber",
            "tailboardFormHeader.location",
            "tailboardFormHeader.dept",
            "user.name"
          ];

          filteredResults = filteredResults.filter(form => {
            let isLike = false;

            for (let field of searchParamsFields) {
              const props = field.split(".");
              const value = props.reduce((acc, p) => (acc = acc[p]), form);

              // NOTE: by using `includes` we are assuming that
              // these searchable properties are primitives.
              // We might have to change that in the future
              // so we can filter arrays, normalize dates, etc
              if (!value || !value.toLowerCase().includes(searchTerm)) continue;

              isLike = true;
              break;
            }
            return isLike;
          });

          // if the user provided a search param but it didn't
          // result in any form being selected we stop here
          // and we return an empty array
          if (!filteredResults || filteredResults.length <= 0) {
            this[fieldName] = [];
            return;
          }
        }

        // Now we search the selected fields
        if (filters && filters.length > 0) {
          filteredResults = filteredResults.filter(form => {
            let isLike = true;

            for (let filter of filters) {
              let { field, operator, value } = filter;
              const props = field.split(".");
              let fieldValue = props.reduce((acc, p) => (acc = acc[p]), form);

              if (!fieldValue) {
                isLike = false;
                break;
              }

              // NOTE: doing a manual conversion for dates here
              // maybe in the future we have data types as part
              // of the filters so we can better transform values
              // NOTE 2: Possible lookup fields are set insite `filters-dialog.vue`
              if (field === "insertedAt") {
                fieldValue = dayjs(fieldValue);
                fieldValue = fieldValue.set("hour", 0);
                fieldValue = fieldValue.set("minute", 0);
                fieldValue = fieldValue.set("second", 0);
                fieldValue = fieldValue.set("millisecond", 0);

                value = dayjs(value);
                value = value.set("hour", 0);
                value = value.set("minute", 0);
                value = value.set("second", 0);
                value = value.set("millisecond", 0);

                // for date comparison in javascript GREATER_THAN and LESS_THAN
                // will work fine with Date objects but for anything
                // that needs an `=` sign we need to use the miliseconds
                // version of the date and we do that by adding the plus sign
                if (operator === "EQUALS_TO") {
                  fieldValue = +fieldValue;
                  value = +value;
                } else {
                  fieldValue = fieldValue.toDate();
                  value = value.toDate();
                }
              } else {
                fieldValue = fieldValue.toLowerCase();
                value = value.toLowerCase();
              }

              if (operator === "EQUALS_TO") isLike = fieldValue === value;
              else if (operator === "CONTAINS")
                isLike = fieldValue.includes(value);
              else if (operator === "DOES_NOT_CONTAIN")
                isLike = !fieldValue.includes(value);
              else if (operator === "GREATER_THAN") isLike = fieldValue > value;
              else if (operator === "LESS_THAN") isLike = fieldValue < value;

              if (!isLike) break;
            }

            return isLike;
          });
        }

        this[fieldName] = filteredResults;
      };

      if (tab) filterField(tab);
      else {
        for (let tab of this.tabs) {
          filterField(tab.fieldName);
        }
      }
    },
    showfilterDialog(param) {
      if (this.displayStyle === 0)
        this.tailboardFormSubmissionsFilterDialog = param;
      else this.archivedTailboardFormSubmissionsFilterDialog = param;
    },

    submitNew() {
      const formID = getEnv("VUE_APP_TAILBOARDFORMID");
      this.$router.push("/forms/" + formID + "/submit");
    }
  }
};
</script>
<style scoped>
.div-top-line {
  border-top: solid 1px var(--v-primary-base);
}

.comment-style {
  border-radius: 5px;
  padding: 20px;
  border: 1px solid var(--v-border-base);
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
</style>
