import { isClaimedByMe, isClaimedBySomeone } from "@/utilities/submission.js";
import * as db from "@/shared/database.js";

export const ClaimsMixin = {
  computed: {
    claimsSubmission() {
      const submission = this.tailboardFormSubmission;
      if (!submission) {
        return null;
      }
      const parent = submission.parentTailboardFormSubmission;
      if (parent) {
        return parent;
      } else {
        return submission;
      }
    },
    isClaimedBySomeone() {
      const submission = this.claimsSubmission;
      if (!submission) {
        return false;
      }
      return isClaimedBySomeone(submission);
    },
    isClaimedByMe() {
      const submission = this.claimsSubmission;
      if (!this.user || !submission) {
        return false;
      }
      return isClaimedByMe(submission, this.user);
    },
    isClaimedBySomeoneElse() {
      return this.isClaimedBySomeone && !this.isClaimedByMe;
    },
    claimedBy() {
      if (this.isClaimedByMe) {
        return this.user.email;
      } else if (this.isClaimedBySomeoneElse) {
        const {
          claims: [{ userId }]
        } = this.claimsSubmission;
        const user = this.users.find(u => u.id === userId);
        if (user) {
          return user.email;
        } else {
          return "unknown";
        }
      } else {
        return "nobody";
      }
    }
  },
  methods: {
    async claimTailboardFormSubmission() {
      const tailboardFormSubmissionId = this.tailboardFormSubmission.id;
      this.$send("tailboardFormSubmission#claim", {
        tailboardFormSubmissionId
      });
    },

    async releaseTailboardFormSubmission() {
      const tailboardFormSubmissionId = this.tailboardFormSubmission.id;

      const handle = await db.mkHandle(this.$bus);
      const submission = await db.fetchSubmission(
        handle,
        tailboardFormSubmissionId
      );

      if (
        submission.claims.length < 1 &&
        submission.claims[0].userId !== this.user.id
      ) {
        throw new Error(
          "Attempting to release claim on submission without claim"
        );
      }

      submission.claims.shift();

      await db.saveSubmission(handle, submission);

      if (this.onClaimUpdatedLocally) {
        await Promise.resolve(this.onClaimUpdatedLocally());
      }

      this.$send("tailboardFormSubmission#release", {
        tailboardFormSubmissionId
      });
    }
  }
};
