import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { concat, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { setContext } from "apollo-link-context";
import { Socket as PhoenixSocket } from "phoenix";
import serviceController from "@/js/service-controller.js";
import getEnv from "@/utilities/env.js";
import store from "@/plugins/store";

Vue.use(VueApollo);

// Create the link the Apollo Client will manage between our frontend client and GraphQL server.
// Note that this is setup for development/demo - deployment will require your real URL.
const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: `${getEnv("VUE_APP_SERVER_URL")}/graphql`,
  credentials: "same-origin"
});

function setOffline() {
  store.commit("setServerActivity", false);
}
// Note: this code expects subscribers to be added before socket events start
// happening. If that is no longer suitable, you should check if the callback
// should be immediately called when it is added so it's up-to-date.

const socket = new PhoenixSocket(`${getEnv("VUE_APP_SERVER_WS")}/socket`, {
  heartbeatIntervalMs: 5000
});

socket.onOpen(() => {
  store.commit("setServerActivity", socket.isConnected());
});
socket.onError(() => {
  setOffline();
});
socket.onClose(() => {
  setOffline();
});

socket.onMessage(async message => {
  if (message.payload.response && message.payload.response.updated_objects) {
    await serviceController.updateEntitiesVersion(
      message.payload.response.updated_objects
    );
  }
});

// the socket is lazy initialised by Absinthe but if we blindy assume we're
// only until the first network request is made, it will give a poor user
// experience with UI errors. Absinthe handles the case where the socket is
// already connected:
// https://github.com/absinthe-graphql/absinthe-socket/blob/a1797c5/packages/socket/src/send.js#L21
// ...so we kick start the socket ourselves.
setTimeout(() => {
  store.commit("setServerActivity", socket.isConnected());
  if (navigator.onLine && !socket.isConnected()) {
    socket.connect();
  }
}, 500);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  createAbsintheSocketLink(AbsintheSocket.create(socket)),
  httpLink
);

// THIS IS ONLY NEEDED IF WE'RE DOING JWT AUTH. WHICH WE ARE NOT!!!
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //""
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Create the apollo client, with the Apollo caching.
export const apolloClient = new ApolloClient({
  link: concat(authLink, link),
  cache: new InMemoryCache({}),
  errorPolicy: "all",
  connectToDevTools: true
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler(error) {
    if (error.networkError && error.networkError.statusCode == 401) {
      location.reload();
    }
  }
});
