<template id="submissionId">
  <div class="submission-main">
    <div v-if="collaps" class="vertical-line" />
    <div
      class="submission-nav white mt-4 ml-4"
      :class="collaps ? 'collaps' : ''"
    >
      <div style="height: 40px">
        <div class="d-flex float-left" v-if="!collaps">
          <p
            class="text-decoration-underline cursor-pointer"
            @click="$router.push('/')"
          >
            Dashboard
          </p>
          <v-icon class="mt-n4" color="primary">navigate_next</v-icon>
          <p>Current Form</p>
        </div>
        <v-btn
          fab
          x-small
          color="white"
          class="float-right"
          @click="collaps = !collaps"
        >
          <v-icon color="primary">{{
            collaps ? "keyboard_arrow_right" : "keyboard_arrow_left"
          }}</v-icon>
        </v-btn>
      </div>
      <div v-if="!collaps">
        <p class="ma-0 primary--text">Form title</p>
        <p>
          {{
            tailboardFormSubmission.tailboardForm
              ? tailboardFormSubmission.tailboardForm.title
              : ""
          }}
        </p>
        <v-divider class=""></v-divider>
        <p v-if="tailboardFormSubmission" class="caption">
          Created :
          {{ tailboardFormSubmission.insertedAt | parseDate }}
        </p>
        <v-btn
          v-if="
            submissionReview.state === 'SUBMITTED' &&
              submissionReview.mode === 'CLOSED' &&
              copyIsAvailable
          "
          class="primary mb-3"
          @click="copyFormSubmission"
        >
          <v-icon class="mr-2">content_copy</v-icon>
          Copy Form
        </v-btn>
      </div>
    </div>
    <div
      ref="sectionContainerId"
      class="submission-section overflow-y-auto overflow-x-hidden"
    >
      <div class="submission-header-data pa-3">
        <div class="header-panel">
          <div>
            <p>Job Details</p>
            <job-information
              :tailboardFormSubmission="tailboardFormSubmission"
              :submissionHeader="
                tailboardFormSubmission.tailboardFormHeader
                  ? tailboardFormSubmission.tailboardFormHeader || {}
                  : {}
              "
            />
          </div>
          <div class="action-group">
            <v-btn
              class="mb-2"
              text
              :disabled="!isClaimedByMe"
              v-if="showEditButton"
              @click="openSubmissionForm"
            >
              <v-icon class="mr-2">edit</v-icon>Edit
            </v-btn>

            <div class="action-subgroup mb-3">
              <v-btn fab small @click="toggleClaimsAction">
                <v-icon>{{ this.toggleClaimsIcon }}</v-icon>
              </v-btn>
              <v-btn class="primary" @click="saveChanges">
                <v-icon class="mr-2">done</v-icon>
                Done
              </v-btn>
            </div>
            <v-btn
              :disabled="submissionReview.isReviewed || !readyToReview"
              :class="[
                submissionReview.isReviewed ? 'white primary--text' : 'primary'
              ]"
              @click="showDialogMessage = true"
            >
              <v-icon v-if="submissionReview.isReviewed" class="mr-2"
                >done</v-icon
              >
              {{
                submissionReview.isReviewed ? "Reviewed" : "Mark as reviewed"
              }}
            </v-btn>
          </div>
        </div>
      </div>

      <div class="submission-header-data pa-3">
        <div class="component-header d-flex">
          <h3>Comment</h3>
          <v-spacer></v-spacer>
          <p v-html="submissionReview.reviewDetails" />
        </div>
        <v-textarea
          class="mt-2"
          hide-details
          outlined
          v-model="submissionReview.comment"
          auto-grow
          :readonly="!isFormSupervisor || submissionReview.isReviewed"
        />
      </div>
      <div class="submission-header-data pa-4">
        <h3 class="component-header mb-3">Review Form</h3>
        <preview :ref="`preview-${submissionId}`">
          <preview-form :submissionId="submissionId" :state="state" />
        </preview>
      </div>
    </div>
    <dialog-message
      :showDialogMessage="showDialogMessage"
      displayCaption="Review"
      displayText="Are you sure you want to mark this form as reviewed ? "
      :okAction="markReviewed"
      :cancelAction="cancelAction"
    />
  </div>
</template>

<script>
import jobInformation from "@/components/submission/job-information.vue";
import dayjs from "@/plugins/dayjs";
import preview from "@/components/submission/preview.vue";
import PreviewForm from "./submission/preview-form/preview-form.vue";
import dialogMessage from "@/components/submission/dialog-message.vue";
import tailboardController from "@/js/tailboards-controller.js";
import utils from "@/shared/Utils";
import { OnlineMixin } from "@/mixins/online.js";
import { ClaimsMixin } from "@/mixins/claims.js";
import { AlertsMixin } from "@/mixins/alerts.js";
import { UsersMixin } from "@/mixins/users.js";
import { NotifiedIdMixin } from "@/mixins/notifiedId.js";
import * as db from "@/shared/database.js";
import { didClaimsChange, isClaimedByMe } from "@/utilities/submission.js";
import { Tables } from "@/shared/consts.js";

export default {
  name: "PreviewComment",

  mixins: [OnlineMixin, ClaimsMixin, AlertsMixin, UsersMixin, NotifiedIdMixin],

  props: {
    state: String,
    submissionId: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: null
    }
  },
  filters: {
    parseDate(val) {
      return dayjs
        .utc(val)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    }
  },

  async mounted() {
    this.tailboardFormSubmission = await tailboardController.getTailboardFormSubmissionAsync(
      this.submissionId,
      this.state
    );

    this.tailboardForm = await tailboardController.getTailboardFormByIdAsync(
      this.tailboardFormSubmission.formId
    );

    this.$listen(
      `update#${Tables.TAILBOARD_FORM_SUBMISSIONS}`,
      async ({ key }) => {
        const handle = await db.mkHandle(this.$bus);
        const submission = await db.fetchSubmission(handle, key);
        this.onSubmissionUpdated(submission);
      }
    );
    this.$listen(
      `delete#${Tables.TAILBOARD_FORM_SUBMISSIONS}`,
      async ({ key }) => this.onSubmissionDeleted(key)
    );
  },

  components: {
    jobInformation,
    preview,
    dialogMessage,
    PreviewForm
  },

  watch: {
    isIPad: {
      immediate: true,
      handler: function(value) {
        this.collaps = value;
      }
    },
    tailboardFormSubmission: {
      immidiate: true,
      deep: true,
      handler: function(value) {
        if (value) {
          this.submissionReview.state = value.state;
          this.submissionReview.mode = value.mode;
          if (value.tailboardFormSubmissionReview) {
            this.submissionReview.comment =
              value.tailboardFormSubmissionReview.comment;
            this.submissionReview.id = value.tailboardFormSubmissionReview.id;
          }
          this.submissionReview.isReviewed = value.endDayReviewed;
          this.submissionReview.isMidDayReviewed = value.midDayReviewed;
          this.submissionReview.isCritical =
            value.criticalTasksAssessment &&
            value.criticalTasksAssessment.criticalTasksIds.length > 0;
          this.submissionReview.reviewDetails = this.formatReviewedDetails(
            value
          );
        }
      }
    }
  },

  data() {
    return {
      collaps: this.isIPad || true,
      doneLoading: false,
      tailboardForm: {},
      tailboardFormSubmission: {},
      showDialogMessage: false,
      submissionReview: {
        state: "",
        mode: "",
        comment: "",
        id: null,
        isReviewed: false,
        isMidDayReviewed: false,
        isCritical: false,
        reviewDetails: ""
      }
    };
  },
  computed: {
    toggleClaimsIcon() {
      if (this.isClaimedByMe) {
        return "lock_open";
      } else {
        return "lock";
      }
    },
    toggleClaimsAction() {
      if (this.isClaimedByMe) {
        return this.releaseTailboardFormSubmission;
      } else {
        return this.claimTailboardFormSubmission;
      }
    },
    isIPad() {
      return this.$store.getters.getIsIPad;
    },
    copyIsAvailable() {
      return dayjs().diff(this.tailboardFormSubmission.insertedAt, "hour") < 18;
    },
    isEditable() {
      return this.user && this.tailboardFormSubmission.user
        ? ["ADMIN", "MANAGER", "SAFETY"].includes(this.user.role) ||
            (this.user.role === "SUPERVISOR" &&
              (this.tailboardFormSubmission.crewMembers.some(
                e => e.employee.id === this.user.employee.id
              ) ||
                this.user.employee.departmentRef ===
                  this.tailboardFormSubmission.user.employee.departmentRef))
        : false;
    },
    isFormSupervisor() {
      return this.user && this.tailboardFormSubmission.user
        ? this.user.role === "SUPERVISOR" &&
            this.user.id !== this.tailboardFormSubmission.user.id &&
            this.user.employee.id === this.tailboardFormSubmission.supervisorId
        : false;
    },
    readyToReview() {
      if (!isClaimedByMe(this.tailboardFormSubmission, this.user)) {
        return false;
      }

      if (this.tailboardFormSubmission.state === "SUBMITTED") {
        if (
          this.tailboardFormSubmission.criticalTasksAssessment &&
          this.tailboardFormSubmission.criticalTasksAssessment.criticalTasksIds
            .length > 0 &&
          !this.tailboardFormSubmission.midDayReviewed &&
          this.tailboardFormSubmission.mode === "OPEN"
        )
          return this.isFormSupervisor;
        else
          return (
            this.tailboardFormSubmission.mode === "CLOSED" &&
            this.isFormSupervisor
          );
      } else return false;
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    async onClaimUpdatedLocally() {
      const handle = await db.mkHandle(this.$bus);
      const submission = await db.fetchSubmission(
        handle,
        this.tailboardFormSubmission.id
      );

      if (didClaimsChange(this.tailboardFormSubmission, submission)) {
        this.tailboardFormSubmission = submission;
        this.showUserMessage("Claims have been updated for this submission");
      }
    },

    onSubmissionUpdated(updatedSubmission) {
      if (updatedSubmission.id !== this.tailboardFormSubmission.id) {
        return;
      }

      this.tailboardFormSubmission = updatedSubmission;
    },

    async onSubmissionDeleted(submissionId) {
      if (submissionId !== this.tailboardFormSubmission.id) {
        return;
      }

      this.showUserMessage(
        `submission '${submissionId}' deleted, navigating to dashboard.`,
        "warn"
      );
      this.$router.push("/");
    },

    showEditButton() {
      if (this.tailboardFormSubmission.user.id === this.user.id) {
        return true;
      }

      return (
        !this.tailboardFormSubmission.isLocked &&
        (this.isFormSupervisor || this.isEditable) &&
        this.submissionReview.mode === "OPEN"
      );
    },

    openSubmissionForm() {
      this.$router.push(
        `/forms/${this.tailboardFormSubmission.formId}/submit/${this.tailboardFormSubmission.id}`
      );
    },

    showUserMessage(message, type = "info") {
      this.$store.commit("addAlert", { message, type });
    },

    async copyFormSubmission() {
      const copiedTailboardFormSubmission = await tailboardController.copyTailboardFormSubmissionAsync(
        this.submissionId,
        dayjs.utc().format("YYYY-MM-DD, HH:mm"),
        this.user
      );

      if (copiedTailboardFormSubmission) {
        this.$router.push(
          `/forms/${copiedTailboardFormSubmission.formId}/submit/${copiedTailboardFormSubmission.submissionId}`
        );

        this.$emit("alertSuccess", "Form copied successfully");

        return true;
      }

      return false;
    },

    dateTimeToLocal(date) {
      return dayjs
        .utc(date)
        .local()
        .format("YYYY-MM-DD, HH:mm");
    },

    formatReviewedDetails(reviewed) {
      let result = "";
      if (reviewed.midDayReviewed) {
        result += `<b>Mid day Reviewed by </b> ${
          reviewed.midDayReviewedByName
        } - ${this.dateTimeToLocal(reviewed.midDayReviewedAt)} </br>`;
      }
      if (reviewed.endDayReviewed) {
        result += `<b>End day Reviewed by </b> ${
          reviewed.endDayReviewedByName
        } - ${this.dateTimeToLocal(reviewed.endDayReviewedAt)} </br>`;
      }
      return result;
    },

    cancelAction() {
      this.showDialogMessage = false;
    },

    async markReviewed() {
      if (this.submissionReview && this.submissionReview.comment)
        await this.tailboardFormSubmissionReviewMutation();
      const midDayReview =
        this.submissionReview.isCritical &&
        !this.submissionReview.isMidDayReviewed &&
        this.submissionReview.mode === "OPEN";
      if (midDayReview) {
        this.tailboardFormSubmission.midDayReviewed = true;
        this.tailboardFormSubmission.midDayReviewedAt = dayjs.utc().format();
        this.tailboardFormSubmission.midDayReviewedBy = this.user.id;
        this.tailboardFormSubmission.midDayReviewedByName = this.user.name;
      } else {
        this.tailboardFormSubmission.endDayReviewed = true;
        this.tailboardFormSubmission.endDayReviewedAt = dayjs.utc().format();
        this.tailboardFormSubmission.endDayReviewedBy = this.user.id;
        this.tailboardFormSubmission.endDayReviewedByName = this.user.name;
      }
      if (
        await tailboardController.saveTailboardFromSubmissionAsync(
          this.tailboardFormSubmission
        )
      )
        this.showDialogMessage = false;
    },

    async saveChanges() {
      if (
        this.submissionReview.isReviewed ||
        !this.isFormSupervisor ||
        !this.submissionReview.comment
      ) {
        this.$router.push("/");
        return;
      }
      let result = await this.tailboardFormSubmissionReviewMutation();
      if (result) this.$router.push("/");
    },

    async tailboardFormSubmissionReviewMutation() {
      if (this.submissionReview.id) {
        this.tailboardFormSubmission.tailboardFormSubmissionReview.updatedAt = dayjs
          .utc()
          .format();
      } else {
        this.tailboardFormSubmission.tailboardFormSubmissionReview = {
          id: utils.getUUID(),
          insertedAt: dayjs.utc().format()
        };
      }
      this.tailboardFormSubmission.tailboardFormSubmissionReview.comment = this.submissionReview.comment;
      this.tailboardFormSubmission.tailboardFormSubmissionReview.user = {
        id: this.user.id,
        name: this.user.name,
        role: this.user.role
      };
      return await tailboardController.saveTailboardFromSubmissionAsync(
        this.tailboardFormSubmission
      );
    }
  }
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.div-indicator {
  width: auto;
}
.div-indicator span {
  padding-top: 2px;
  text-align: center;
  float: right;
  width: 22px !important;
  height: 22px;
  border-radius: 11px;
  background-color: var(--v-error-base);
  color: white;
  font-size: 12px;
  margin-right: 2px;
}

.section-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.submission-section {
  flex: auto;
}

.submission-nav {
  flex: 0;
  min-width: 280px;
  padding: 15px 15px;
}
.collaps {
  min-width: 62px !important;
}

.submission-nav > div:first-child {
  height: 230px;
}

.submission-main {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 10px;
  right: 0;
}

.flex-auto {
  flex: auto;
}

label {
  font-size: 10px;
}
.component-header p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 11px !important;
}

.header-panel {
  display: flex;
  flex-flow: row;
}

.header-panel div:nth-child(1) {
  flex: auto;
}

.header-panel div:nth-child(2) {
  flex: 0;
}
.vertical-line {
  border-left: 1px solid silver;
  position: absolute;
  top: 18px;
  bottom: 18px;
  left: 48px;
  z-index: 0;
}

.action-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.action-group div.action-subgroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  width: 100%;
}

.action-group .action-subgroup > :not(:last-child) {
  margin-right: 3px;
}

.action-group .action-subgroup button i {
  display: block;
}
</style>
