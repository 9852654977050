import gql from "graphql-tag";
import {
  EMPLOYEE_ENTITY_FRAGMENT,
  USER_ENTITY_FRAGMENT
} from "@/graphql/accounts/fragments.js";

export const SIGNATURE_ENTITY_FRAGMENT = gql`
  fragment SignatureEntity on Signature {
    id
    name
    file
    signedAt
    submissionId
    phase
    mode
    employee {
      ...EmployeeEntity
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
`;

export const TAILBOARD_FORM_ENTITY_FRAGMENT = gql`
  fragment TailboardFormEntity on TailboardForm {
    childForms {
      title
      header
      instructions
      logoFile
      id
      type
      sections {
        title
        description
        layout
        position
        tableHeadings
        id
        applicabilityPrompt
        components {
          title
          layout
          position
          id
          applicabilityPrompt
          customType
          inputFields {
            id
            name
            defaultValue
            description
            inputType
            position
            placeholderText
            relatedAsset
            allowOther
            allowNotApplicable
            displayInline
            options {
              value
              text
            }
            validations {
              containedValues
              id
              limit
              mode
              notEqualFieldId
              otherFieldId
              otherFieldValue
              type
            }
          }
        }
      }
    }
    title
    header
    instructions
    logoFile
    id
    type
    sections {
      title
      description
      layout
      position
      tableHeadings
      id
      applicabilityPrompt
      components {
        title
        layout
        position
        id
        applicabilityPrompt
        customType
        inputFields {
          id
          name
          defaultValue
          description
          inputType
          position
          placeholderText
          relatedAsset
          allowOther
          allowNotApplicable
          displayInline
          options {
            value
            text
          }
          validations {
            containedValues
            id
            limit
            mode
            notEqualFieldId
            otherFieldId
            otherFieldValue
            type
          }
        }
      }
    }
  }
`;
export const FORM_SUBMISSION_ENTITY_FRAGMENT = gql`
  fragment FormSubmissionEntity on FormSubmission {
    data
    formVersion
    isLocked
    pdfFile
    insertedAt
  }
`;

export const TAILBOARD_FORM_SUBMISSION_COMMENT_ENTITY_FRAGMENT = gql`
  fragment TailboardFormSubmissionCommentEntity on TailboardFormSubmission {
    id
    formId
    insertedAt
    state
    mode
    supervisorId
    supervisorPhone
    crewLeaderId
    crewLeaderPhone
    endDayReviewed
    endDayReviewedAt
    endDayReviewedByName
    midDayReviewed
    midDayReviewedAt
    midDayReviewedByName
    hasCriticalTasks
    crewMembers {
      employee {
        id
      }
    }
    tailboardFormHeader {
      esrr
      date
      dept
      id
      location
      projectName
      rcNumber
      wbs
      workDescription
      workOrderNumber
      fieldActivityId
    }
    tailboardForm {
      header
      id
      instructions
      logoFile
      title
      type
    }
    tailboardFormSubmissionReview {
      comment
      id
      insertedAt
      updatedAt
      user {
        name
        id
        role
      }
    }
    user {
      employee {
        departmentRef
        id
      }
      id
    }
  }
`;

// TODO: Refactor fragments, queries, mutations, subscriptions, etc.
export const TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT = gql`
  fragment TailboardFormSubmissionEntity on TailboardFormSubmission {
    id
    formId
    offline
    isLocked
    isLockedBy {
      id
      name
    }
    updatedAt
    updatedBy
    supervisorId
    supervisorPhone
    crewLeaderId
    crewLeaderPhone
    endDayReviewed
    endDayReviewedAt
    endDayReviewedByName
    midDayReviewed
    midDayReviewedAt
    midDayReviewedByName
    hasCriticalTasks
    incidentReported
    incidentReportedAt
    incidentReportedBy {
      name
      id
      role
    }
    employeeEntryLogs {
      componentId
      sectionId
      employeeId
      id
      timeIn
      timeOut
    }
    formVersion
    inapplicableSections
    inapplicableComponents
    visitors
    crewMembers {
      id
      vehicle {
        id
        isTrailer
        vehicleRef
      }
      trailer {
        id
        isTrailer
        vehicleRef
      }
      employee {
        ...EmployeeEntity
      }
    }
    inputFieldData {
      id
      inputFieldId
      value
    }
    signatures {
      ...SignatureEntity
    }
    insertedAt
    mode
    pdfFile
    state
    user {
      ...UserEntity
    }
    riskAssessment {
      id
      tailboardFormSubmissionId
      workType {
        id
        name
      }
      hazardIds
      jobSteps {
        id
        name
        step
        employeeIds
        jobStepHazards {
          id
          source
          position
          barriers
          hazardId
        }
      }
    }
    confinedSpaceSubstanceTests {
      bottomCo
      bottomH2s
      bottomLel
      bottomO2
      bottomOther
      componentId
      sectionId
      id
      midCo
      midH2s
      midLel
      midO2
      midOther
      time
      topCo
      topH2s
      topLel
      topO2
      topOther
    }
    cableChamberFeeders {
      comments
      componentId
      sectionId
      deltaS
      direction
      ductNumber
      feederDesignation
      hazards
      id
      maxTemp
      spotTemp
    }
    criticalTasksAssessment {
      criticalTasksIds
      id
    }
    childTailboardFormSubmissions {
      formId
      id
      insertedAt
      mode
      state
      isLocked
      isLockedBy {
        id
        name
      }
      user {
        ...UserEntity
      }
      tailboardForm {
        header
        id
        instructions
        logoFile
        title
        type
      }
    }
    tailboardFormHeader {
      esrr
      date
      dept
      id
      location
      projectName
      rcNumber
      wbs
      workDescription
      workOrderNumber
      fieldActivityId
    }
    tailboardForm {
      header
      id
      instructions
      logoFile
      title
      type
    }
    parentTailboardFormSubmission {
      formId
      id
      insertedAt
      state
      claims {
        userId
        tailboardFormSubmissionId
      }
      tailboardForm {
        header
        id
        instructions
        logoFile
        title
        type
      }
    }
    tailboardFormSubmissionReview {
      comment
      id
      insertedAt
      updatedAt
      user {
        name
        role
        id
      }
    }
  }
  ${EMPLOYEE_ENTITY_FRAGMENT}
  ${SIGNATURE_ENTITY_FRAGMENT}
  ${USER_ENTITY_FRAGMENT}
`;

export const TAILBOARD_FORM_SUBMISSION_PAGE_ENTITY_FRAGMENT = gql`
  fragment TailboardFormSubmissionPageEntity on TailboardFormSubmissionPage {
    metadata {
      after
      before
    }
    entries {
      ...TailboardFormSubmissionEntity
    }
  }
  ${TAILBOARD_FORM_SUBMISSION_ENTITY_FRAGMENT}
`;
