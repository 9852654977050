import tailboardController from "@/js/tailboards-controller.js";

export const NotifiedIdMixin = {
  computed: {
    notifiedId() {
      return this.$store.getters.getNotifiedId;
    }
  },
  watch: {
    notifiedId: {
      immediate: true,
      async handler(submissionId) {
        try {
          if (submissionId) {
            const updatedSubmission = await tailboardController.getTailboardFormSubmissionAsync(
              submissionId
            );
            if (updatedSubmission) {
              await this.onSubmissionUpdated(updatedSubmission);
            } else {
              await this.onSubmissionDeleted(submissionId);
            }
          }
        } finally {
          this.$store.commit("setNotifiedId", null);
        }
      }
    }
  }
};
