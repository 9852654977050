import getEnv from "@/utilities/env.js";
import { Tables } from "@/shared/consts.js";
import globalNamespace from "@/shared/global-namespace.js";

async function mkTransaction(handle, table, mode) {
  const { db } = handle;
  return db.transaction([table], mode);
}

function conditionallyCreateTable(db, table, callback) {
  if (!db.objectStoreNames.contains(table)) {
    const objectStore = db.createObjectStore(table);
    if (callback != null) {
      callback(objectStore);
    }
  }
}

async function saveEntitiesByDerived(handle, table, entities, deriveKey) {
  const transaction = await mkTransaction(handle, table, "readwrite");

  transaction.addEventListener("complete", () => {
    const keys = entities.map(deriveKey);
    for (const key of keys) {
      handle.bus.emit(`update#${table}`, { key });
    }
    return entities;
  });

  transaction.addEventListener("error", event => {
    event.preventDefault();
    const keys = entities.map(deriveKey).join(", ");
    throw new Error(`failed to save ${table} with keys ${keys}`);
  });

  const store = transaction.objectStore(table);

  for (const entity of entities) {
    const key = deriveKey(entity);
    store.put(entity, key);
  }
}

async function saveEntityById(handle, table, entity) {
  if (!entity.id) {
    throw new Error("entity is missing id");
  }

  return saveEntityByKey(handle, table, entity, entity.id);
}

async function saveEntityByKey(handle, table, entity, key) {
  const transaction = await mkTransaction(handle, table, "readwrite");

  transaction.addEventListener("complete", () => {
    handle.bus.emit(`update#${table}`, { key });
    return entity;
  });

  transaction.addEventListener("error", event => {
    event.preventDefault();
    throw new Error(`failed to save ${table} with key ${key}`);
  });

  const store = transaction.objectStore(table);

  store.put(entity, key);
}

async function deleteEntityById(handle, table, key) {
  const transaction = await mkTransaction(handle, table, "readwrite");

  transaction.addEventListener("complete", () => {
    handle.bus.emit(`delete#${table}`, { key });
    return;
  });

  transaction.addEventListener("error", event => {
    event.preventDefault();
    throw new Error(`failed to delete ${table} with key ${key}`);
  });

  const store = transaction.objectStore(table);

  store.delete(key);
}

async function fetchEntityByKey(handle, table, key) {
  const transaction = await mkTransaction(handle, table, "readonly");
  const store = transaction.objectStore(table);
  const request = store.get(key);

  return new Promise((resolve, reject) => {
    request.addEventListener("success", event => {
      const entity = event.target.result;
      if (entity) {
        resolve(entity);
      } else {
        const msg = `${table} entity not found: ${key}`;
        const err = new Error(msg);
        reject(err);
      }
    });

    request.addEventListener("error", event => {
      const err = event.target.error;
      reject(err);
    });
  });
}

async function fetchEntities(handle, table) {
  const transaction = await mkTransaction(handle, table, "readonly");
  const store = transaction.objectStore(table);
  const request = store.openCursor();

  return new Promise(resolve => {
    const entities = [];

    transaction.addEventListener("complete", () => {
      resolve(entities);
    });

    request.addEventListener("success", ({ target: { result } }) => {
      if (result) {
        entities.push(result.value);
        result.continue();
      }
    });
  });
}

// ------------------ public ------------------ //

const DATABASE_ACCESS = Symbol("database");

async function openDatabase() {
  if (globalNamespace[DATABASE_ACCESS]) {
    return Promise.resolve(globalNamespace[DATABASE_ACCESS]);
  }

  const name = getEnv("VUE_APP_LOCAL_DATABASE");
  const version = getEnv("VUE_APP_LOCAL_DATABASE_VERSION");

  return new Promise((resolve, reject) => {
    const request = globalNamespace.indexedDB.open(name, version);

    request.addEventListener("success", ({ target: { result } }) => {
      resolve(result);
    });

    request.addEventListener("error", () => {
      reject(new Error("failed to open database."));
    });

    request.addEventListener("upgradeneeded", e => {
      let db = e.target.result;
      conditionallyCreateTable(db, Tables.TAILBOARD_FORMS);
      conditionallyCreateTable(db, Tables.TAILBOARD_FORM_SUBMISSIONS);
      conditionallyCreateTable(db, Tables.ARCHIVED_TAILBOARD_FORM_SUBMISSIONS);
      conditionallyCreateTable(db, Tables.USERS, users => {
        users.createIndex("employeeId", "employee.id", { unique: true });
      });
      conditionallyCreateTable(db, Tables.USER);
      conditionallyCreateTable(db, Tables.HAZARDS);
      conditionallyCreateTable(db, Tables.HAZARD_CATEGORIES);
      conditionallyCreateTable(db, Tables.BARRIERS);
      conditionallyCreateTable(db, Tables.WORK_TYPES);
      conditionallyCreateTable(db, Tables.EMPLOYEES);
      conditionallyCreateTable(db, Tables.CRITICAL_TASKS);
      conditionallyCreateTable(db, Tables.VEHICLES);
      conditionallyCreateTable(db, Tables.UPDATED_ENTITIES);
      conditionallyCreateTable(db, Tables.DELETED_CHILDS_QUEUE);
      conditionallyCreateTable(db, Tables.TAILBOARDS_QUEUE);
      conditionallyCreateTable(db, Tables.TRAFFIC_ASSETS, assets => {
        assets.createIndex("type", "type", { unique: false });
      });
      conditionallyCreateTable(db, Tables.FEEDERS);
      conditionallyCreateTable(db, Tables.STRUCTURES);
      conditionallyCreateTable(db, Tables.SUBMISSION_CLAIMS_QUEUE);
    });
  });
}

async function mkHandle(bus) {
  const db = await openDatabase();
  return { db, bus };
}

async function saveSubmission(handle, submission) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  return saveEntityById(handle, table, submission);
}

async function saveSubmissions(handle, submissions) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  return saveEntitiesByDerived(
    handle,
    table,
    submissions,
    submission => submission.id
  );
}

function fetchQueuedSubmissions(handle) {
  const table = Tables.TAILBOARDS_QUEUE;

  return fetchEntities(handle, table);
}

function fetchQueuedClaims(handle) {
  const table = Tables.SUBMISSION_CLAIMS_QUEUE;

  return fetchEntities(handle, table);
}

function deleteQueuedSubmission(handle, id) {
  const table = Tables.TAILBOARDS_QUEUE;

  return deleteEntityById(handle, table, id);
}

function deleteQueuedClaim(handle, id) {
  const table = Tables.SUBMISSION_CLAIMS_QUEUE;

  return deleteEntityById(handle, table, id);
}

function deleteSubmission(handle, id) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  return deleteEntityById(handle, table, id);
}

function fetchUsers(handle) {
  const table = Tables.USERS;

  return fetchEntities(handle, table);
}

function fetchSubmission(handle, id) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  return fetchEntityByKey(handle, table, id);
}

function fetchSubmissions(handle) {
  const table = Tables.TAILBOARD_FORM_SUBMISSIONS;

  return fetchEntities(handle, table);
}

async function queueClaimAction(handle, action) {
  const table = Tables.SUBMISSION_CLAIMS_QUEUE;

  const validTypes = ["claim", "release"];
  if (!validTypes.includes(action.type)) {
    throw new Error(
      `Action type ${
        action.type
      } not included in valid claim action types ${JSON.stringify(validTypes)}`
    );
  }

  return saveEntityById(handle, table, {
    id: action.tailboardFormSubmissionId,
    attemptCount: action.attemptCount || 0,
    type: action.type
  });
}

export {
  deleteQueuedClaim,
  deleteQueuedSubmission,
  deleteSubmission,
  fetchQueuedClaims,
  fetchQueuedSubmissions,
  fetchSubmission,
  fetchSubmissions,
  fetchUsers,
  mkHandle,
  openDatabase,
  queueClaimAction,
  saveSubmission,
  saveSubmissions
};
