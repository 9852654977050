import globalNamespace from "@/shared/global-namespace.js";

export default function getEnv(name) {
  if (
    globalNamespace.configuration &&
    globalNamespace.configuration[name] &&
    globalNamespace.configuration[name] != "" &&
    globalNamespace.configuration[name] != "undefined"
  ) {
    return globalNamespace.configuration[name];
  } else {
    return process.env[name];
  }
}
