export const DEFAULT_TRAFFIC_PLAN_BACKGROUND_NAME =
  "0_ETailboard Project_Blank.jpg";
export const DEFAULT_TRAFFIC_PLAN_BACKGROUND_UUID =
  "d1bdaea8-7a6d-4b6f-be3f-b6ce9754c313";
export const DEFAULT_QUEUE_CACHE = "tailboard-files-queue";
export const LOCAL_STORAGE_SYNC_DATE_KEY = "tailboard-app-sync-date";
export const LOCAL_STORAGE_FILTER_STATE_KEY = "tailboard-app-filters-state";
export const TAILBOARD_SUBMISSIONS_FILES_CACHE = "tailboard-submissions-files";

export const Tables = {
  TAILBOARD_FORMS: "tailboardForms",
  TAILBOARD_FORM_SUBMISSIONS: "tailboardFormSubmissions",
  ARCHIVED_TAILBOARD_FORM_SUBMISSIONS: "archivedTailboardFormSubmissions",
  USERS: "users",
  USER: "user",
  HAZARDS: "hazards",
  HAZARD_CATEGORIES: "hazardCategories",
  BARRIERS: "barriers",
  WORK_TYPES: "workTypes",
  EMPLOYEES: "employees",
  CRITICAL_TASKS: "criticalTasks",
  VEHICLES: "vehicles",
  UPDATED_ENTITIES: "updatedEntities",
  DELETED_CHILDS_QUEUE: "deletedChildsQueue",
  TAILBOARDS_QUEUE: "tailboardsQueue",
  /**
   * The claims queue is used to schedule the acquisition/release of claims
   * for a TailboardFormSubmission by the update worker
   */
  SUBMISSION_CLAIMS_QUEUE: "tailboardFormSubmissionClaimsQueue",
  TRAFFIC_ASSETS: "trafficAssets",
  FEEDERS: "feeders",
  STRUCTURES: "structures"
};
