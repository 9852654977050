export const OnlineMixin = {
  computed: {
    isOnline() {
      return (
        this.$store.getters.getConnected &&
        this.$store.getters.getServerActivity
      );
    }
  }
};
